
// This file is generated by `grunt handlebars` and should not be modified directly

/* jshint ignore:start */
import Handlebars from "lib/handlebars.runtime-v2.0.0.js";
import "app/templates/_helpers/subview";
import "app/templates/_helpers/lower";
import "app/templates/_helpers/unformatMoney";
import "app/templates/_helpers/formatMoney";

var templates = {};

// Partials
Handlebars.registerPartial("_applePay", Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<div class=\"at-payment-integration-container\" style=\"display: none;\">\n    <div class=\"at-apple-pay-button at-apple-pay-button-logo\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ApplePay : stack1), depth0))
    + "\"></div>\n    <p>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AuthorizedThroughApplePay : stack1), depth0))
    + "</p>\n    <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ContributionAmount : stack1), depth0))
    + ": <b class=\"apple_pay_amount\"></b></div>\n    <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.CardInformation : stack1), depth0))
    + ": <b class=\"apple_pay_card_info\"></b></div>\n</div>\n<label class=\"apple-pay-label\">\n    <input type=\"hidden\" name=\"paymentResponseDetails\" class=\"apple_pay_payment_details\" value=\"\" />\n</label>\n";
},"useData":true}));

Handlebars.registerPartial("_cc_readonly", Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.cvv : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, (depth0 != null ? depth0.cvv : depth0), {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row\">\n    ";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, (depth0 != null ? depth0.fa : depth0), {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row at-row-full\">\n  <label class=\"at-text at-cc-number at-cc-number-readonly\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.CardNumber : stack1), depth0))
    + "\n    <a href=\"#edit-account\" class=\"change-account\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Edit : stack1), depth0))
    + "</a>\n    <div class=\"cc-type-wrapper\">\n      <div class=\"cc-type unknown\"></div>\n      <input readonly disabled name=\"Account\" type=\"tel\" title=\"Credit "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" value=\"•••• •••• •••• "
    + escapeExpression(((helper = (helper = helpers.cc_4_digit || (depth0 != null ? depth0.cc_4_digit : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cc_4_digit","hash":{},"data":data}) : helper)))
    + "\">\n    </div>\n  </label>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.savedCardRequiresSecurityCode : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.fa : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true}));

Handlebars.registerPartial("_eft", Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "  <div class=\"at-eft-legal-disclaimer at-mode-org-only\">\n    ";
  stack1 = ((helper = (helper = helpers.eftLegalDisclaimer || (depth0 != null ? depth0.eftLegalDisclaimer : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"eftLegalDisclaimer","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.eftLegalDisclaimer : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<label class=\"account-type-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AccountType : stack1), depth0))
    + "\n  <select title=\"Account Type\" name=\"bankAccountType\" id=\"bankAccountType\" style=\"width:50%;\" class=\"eft-input\">\n    <option value=\"Checking\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Checking : stack1), depth0))
    + "</option>\n    <option value=\"Savings\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Savings : stack1), depth0))
    + "</option>\n  </select>\n</label>\n<label class=\"routing-number-label at-row-half\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.RoutingNumber : stack1), depth0))
    + " <a class=\"at-whatsthis\" tabindex=\"0\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.WhatsThis : stack1), depth0))
    + "</a>\n  <input type=\"tel\" name=\"routingNumber\" id=\"routingNumber\" placeholder=\"•••••••••\" style=\"width:100%;\" maxlength=\"9\" class=\"eft-input\"/>\n</label>\n<label class=\"account-number-label at-row-half\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AccountNumber : stack1), depth0))
    + " <a class=\"at-whatsthis\" tabindex=\"0\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.WhatsThis : stack1), depth0))
    + "</a>\n  <input type=\"tel\" name=\"bankAccountNumber\" id=\"bankAccountNumber\" placeholder=\"•••••••••••••••••\" style=\"width:100%;\" maxlength=\"17\" class=\"eft-input\"/>\n</label>\n<label class=\"confirm-account-number-label at-row-half\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ConfirmAccountNumber : stack1), depth0))
    + "\n  <input type=\"tel\" name=\"confirmBankAccountNumber\" id=\"confirmBankAccountNumber\" placeholder=\"•••••••••••••••••\" style=\"width:100%;\" maxlength=\"17\" class=\"eft-input\"/>\n</label>\n<label class=\"at-check authorize-payment-label\">\n  <input type=\"checkbox\" name=\"authorizePayment\" id=\"authorizePayment\" class=\"eft-input\"/>\n  <span>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.EftAuthorizeDisclaimer : stack1), depth0))
    + "</span>\n</label>\n";
},"useData":true}));

Handlebars.registerPartial("_googlePay", Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<div class=\"at-payment-integration-container\" style=\"display: none;\">\n    <img class=\"at-google-pay-mark\" src=\""
    + escapeExpression(((helper = (helper = helpers.googlePayMarkUrl || (depth0 != null ? depth0.googlePayMarkUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"googlePayMarkUrl","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.GooglePay : stack1), depth0))
    + "\"/>\n    <p>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AuthorizedThroughGooglePay : stack1), depth0))
    + "</p>\n    <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ContributionAmount : stack1), depth0))
    + ": <b class=\"google_pay_amount\"></b></div>\n    <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.CardInformation : stack1), depth0))
    + ": <b class=\"google_pay_card_info\"></b></div>\n</div>\n<label class=\"google-pay-label\">\n    <input type=\"hidden\" name=\"confirmationTokenId\" class=\"confirmation_token_id\" value=\"\" />\n</label>\n";
},"useData":true}));

Handlebars.registerPartial("_label", Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "<kbd>";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"label","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
},"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " <small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"9":function(depth0,helpers,partials,data) {
  return "</kbd>";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.labelhide : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.label : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.labelhide : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true}));

Handlebars.registerPartial("_payment_method_subview_div", Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<div data-name=\"PaymentMethod\" data-subview=\"payment_method_view\" data-subview-index=\"-1\" data-cache-key=\"PaymentMethod\" />";
  },"useData":true}));

Handlebars.registerPartial("_paypal", Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "block";
  },"3":function(depth0,helpers,partials,data) {
  return "none";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"at-payment-integration-container\" style=\"display: ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paypalNonce : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ";\">\n  <img src=\""
    + escapeExpression(((helper = (helper = helpers.paypalLogoUrl || (depth0 != null ? depth0.paypalLogoUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"paypalLogoUrl","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayPal : stack1), depth0))
    + "\">\n  <p>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AuthorizedThroughPayPal : stack1), depth0))
    + "</p>\n  <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ContributionAmount : stack1), depth0))
    + ": <b class=\"at-paypal-amount\">"
    + escapeExpression(((helper = (helper = helpers.amount || (depth0 != null ? depth0.amount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"amount","hash":{},"data":data}) : helper)))
    + "</b></div>\n  <div>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Account : stack1), depth0))
    + ": <b class=\"at-paypal-account-info\">"
    + escapeExpression(((helper = (helper = helpers.paypalAccount || (depth0 != null ? depth0.paypalAccount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"paypalAccount","hash":{},"data":data}) : helper)))
    + "</b></div>\n</div>\n<label class=\"paypal-label\">\n  <input type=\"hidden\" name=\"paypalNonce\" value=\""
    + escapeExpression(((helper = (helper = helpers.paypalNonce || (depth0 != null ? depth0.paypalNonce : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"paypalNonce","hash":{},"data":data}) : helper)))
    + "\"/>\n</label>\n\n";
},"useData":true}));

Handlebars.registerPartial("_rows", Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.rows : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"at-row "
    + escapeExpression(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"classes","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.children : depth0), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row at-row-full "
    + escapeExpression(lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "\">\n      ";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.rows : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true}));

Handlebars.registerPartial("_secure-processing", Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<label class=\"secure-processing-label at-text\">\n    "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.DonationWillBeSecurelyProcessed : stack1), depth0))
    + "<div class=\"glyphicons glyphicons-lock\" aria-hidden=\"true\"></div>\n</label>\n";
},"useData":true}));

// Full templates
templates["advocacy_tweet"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<br>"
    + escapeExpression(((helper = (helper = helpers.meta || (depth0 != null ? depth0.meta : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meta","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<br>\n                <img class=\"at-twitter-account\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/twitter_account_icon.svg\">";
},"5":function(depth0,helpers,partials,data) {
  return " (read only)";
  },"7":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"8":function(depth0,helpers,partials,data) {
  return " (required)";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"at-advocacy-tweet-field\">\n    <figure>\n        <div class=\"at-target-fallback\">\n            <div class=\"at-target-image\" style=\"background-image: url('"
    + escapeExpression(((helper = (helper = helpers.img || (depth0 != null ? depth0.img : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img","hash":{},"data":data}) : helper)))
    + "')\">\n                <img src=\""
    + escapeExpression(((helper = (helper = helpers.img || (depth0 != null ? depth0.img : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img","hash":{},"data":data}) : helper)))
    + "\">\n            </div>\n        </div>\n        <figcaption>\n            <b>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</b>\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasTwitter : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</figcaption>\n    </figure>\n    <div class=\"at-tweet-area\">\n        <label class=\"at-tweet-label\">\n            <textarea class=\"at-tweet-message\" "
    + escapeExpression(((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.readonly || (depth0 != null ? depth0.readonly : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"readonly","hash":{},"data":data}) : helper)))
    + " title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" maxlength=\""
    + escapeExpression(((helper = (helper = helpers.maxlength || (depth0 != null ? depth0.maxlength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"maxlength","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">"
    + escapeExpression(((helper = (helper = helpers.default_value || (depth0 != null ? depth0.default_value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"default_value","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </label>\n        <button class=\"at-tweet-button btn-at-primary\">\n            <img src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/white_twitter_icon.svg\"></img>\n            <span>Tweet</span>\n        </button>\n    </div>\n    <div class=\"at-tweet-closed-msg\" style=\"display: none\">\n        <div class=\"green-check\"></div>\n        <h3>Thanks!</h3>\n        <p>Closed the window by mistake? <a class=\"at-show-tweet\" href=\"tweetAgain\">Click here</a> to post your Tweet.\n    </div>\n</div>\n\n";
},"useData":true});

templates["amount"] = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<label class=\"label-amount";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.other : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isLong : depth0), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" title=\"";
  stack1 = ((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <input name=\"SelectAmount\" type=\"radio\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.other : depth0), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " value=\""
    + escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"val","hash":{},"data":data}) : helper)))
    + "\">\n        ";
  stack1 = ((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.other : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.program(15, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>";
},"2":function(depth0,helpers,partials,data) {
  return " label-otheramount";
  },"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " "
    + escapeExpression(((helper = (helper = helpers.isLong || (depth0 != null ? depth0.isLong : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"isLong","hash":{},"data":data}) : helper)));
},"6":function(depth0,helpers,partials,data) {
  return "class=\"radio-other\" ";
  },"8":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<input type=\"number\" tabindex=\"-1\" autocomplete=\"transaction-amount\" class=\"edit-otheramount\" name=\"OtherAmount\"\n            title=\"Other Amount\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.otherAmountPlaceholderOverride : depth0), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.program(11, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n";
  stack1 = helpers['if'].call(depth0, (depths[2] != null ? depths[2].isUSD : depths[2]), {"name":"if","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " placeholder=\""
    + escapeExpression(((helper = (helper = helpers.otherAmountPlaceholderOverride || (depth0 != null ? depth0.otherAmountPlaceholderOverride : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"otherAmountPlaceholderOverride","hash":{},"data":data}) : helper)))
    + "\"\n            ";
},"11":function(depth0,helpers,partials,data) {
  return " placeholder=\"0.00\" ";
  },"13":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <span class=\"label-otheramount-prefix\">"
    + escapeExpression(lambda(((stack1 = (depths[3] != null ? depths[3].currency : depths[3])) != null ? stack1.symbol : stack1), depth0))
    + "</span>\n";
},"15":function(depth0,helpers,partials,data) {
  return "<a></a> ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<div class=\"at-row SelectAmount OtherAmount NonRecurringButtons\">\n  <div class=\"at-radio\">\n    <div class=\"at-radios clearfix\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.amount_options : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});

templates["captcha"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "    <div class=\"at-captcha\" data-idx=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n      <div class=\"at-row at-row-full\">\n        <span class=\"at-captcha-header\">\n          Deliver your message to "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.simpleCaptcha : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </span>\n      </div>\n      <div class=\"at-row\">\n        <label class=\"at-captcha-challenge\" style=\"cursor: default\">\n          <div style=\"margin-bottom: 1rem\">\n            <div class=\"spinner-wrapper\"></div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.simpleCaptcha : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <a href=\"#newCaptcha\" class=\"get-new-captcha-link\">Try a different image</a>\n          </div>\n        </label>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.simpleCaptcha : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "            by entering the text shown below\n";
  },"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            by selecting all images of <b class=\"at-captcha-phrase\">"
    + escapeExpression(((helper = (helper = helpers.captchaPhrase || (depth0 != null ? depth0.captchaPhrase : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"captchaPhrase","hash":{},"data":data}) : helper)))
    + "</b>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "              <img class=\"at-captcha-image\" src=\""
    + escapeExpression(((helper = (helper = helpers.img || (depth0 != null ? depth0.img : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img","hash":{},"data":data}) : helper)))
    + "\">\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "                ";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, (depth0 != null ? depth0.input : depth0), {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "          ";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, (depth0 != null ? depth0.input : depth0), {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>\n<div class=\"at-fields\">\n  <div class=\"at-row\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.captchas : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"useData":true});

templates["cc_expiration"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<input type=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" pattern=\""
    + escapeExpression(((helper = (helper = helpers.pattern || (depth0 != null ? depth0.pattern : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"pattern","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" autocomplete=\"cc-exp\" title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + " ("
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + ")\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" min=\""
    + escapeExpression(((helper = (helper = helpers.min || (depth0 != null ? depth0.min : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"min","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"7\">\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"vgs-loading-placeholder\">\n  <input type=\"tel\" autocomplete=\"cc-exp\" title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + " ("
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + ")\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" readonly=\"true\">\n</div>\n<div id=\""
    + escapeExpression(((helper = (helper = helpers.vgsContainerId || (depth0 != null ? depth0.vgsContainerId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"vgsContainerId","hash":{},"data":data}) : helper)))
    + "\" class=\"vgs-ccexpiration-iframe-wrapper  vgs-input-container loading\"></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});

templates["checkbox"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<input type=\"checkbox\" "
    + escapeExpression(((helper = (helper = helpers.checked || (depth0 != null ? depth0.checked : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"checked","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper)))
    + " name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"> <span class=\"at-checkbox-title-container\"><span class=\"at-checkbox-title\" id=\""
    + escapeExpression(((helper = (helper = helpers.labelId || (depth0 != null ? depth0.labelId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"labelId","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</span></span>\n";
},"useData":true});

templates["checkbox_list_manager"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<label name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"checkbox-list-label\">\n    "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "&nbsp;";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<br />\n    <small class=\"checkbox-list-required-text\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "-small\" style=\"display: none\"><b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.TitleIsRequired : stack1), depth0))
    + "</b></small>\n</label>\n\n";
},"useData":true});

templates["contribution"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-recurring\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.includeFrequencyPlaceholder : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.recurring : depth0), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"4":function(depth0,helpers,partials,data) {
  return "<label class=\"at-recurring-frequency\"></label>";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <div name=\"AmountSubtitle\" class=\"at-subtitle\">\n      "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ContributionAcceptedIn : stack1), depth0))
    + " <b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.currency : depth0)) != null ? stack1.code : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.currency : depth0)) != null ? stack1.symbol : stack1), depth0))
    + " - "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.currency : depth0)) != null ? stack1.name : stack1), depth0))
    + "</b>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-fields\">\n";
  stack1 = this.invokePartial(partials._rows, '  ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.recurring : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.afterRecurring : depth0), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showCurrencyNote : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});

templates["credit_card"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"cc-type-wrapper\">\n  <div class=\"cc-type unknown\"></div>\n  <input type=\"tel\" autocomplete=\"cc-number\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" title=\"Credit "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" maxlength=\""
    + escapeExpression(((helper = (helper = helpers.maxlength || (depth0 != null ? depth0.maxlength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"maxlength","hash":{},"data":data}) : helper)))
    + "\" minlength=\""
    + escapeExpression(((helper = (helper = helpers.minlength || (depth0 != null ? depth0.minlength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"minlength","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.pattern || (depth0 != null ? depth0.pattern : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"pattern","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"cc-type-wrapper vgs-loading-placeholder\">\n    <div class=\"cc-type unknown\"></div>\n    <input type=\"tel\" autocomplete=\"cc-number\" title=\"Credit "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" readonly=\"true\">\n</div>\n<div id=\""
    + escapeExpression(((helper = (helper = helpers.vgsContainerId || (depth0 != null ? depth0.vgsContainerId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"vgsContainerId","hash":{},"data":data}) : helper)))
    + "\" class=\"vgs-cc-iframe-wrapper vgs-input-container loading\" tabindex=\"-1\"></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});

templates["cvv2"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"cvc-type-wrapper\">\n  <div class=\"cvc-type\"></div>\n  <input type=\"tel\" autocomplete=\"cc-csc\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" pattern=\"[0-9]{3,4}\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"4\" minlength=\"3\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"vgs-cvv-iframe-wrapper vgs-input-container\">\n    <div class=\"cvc-type-wrapper\">\n        <div class=\"cvc-type\"></div>\n        <input type=\"tel\" autocomplete=\"cc-csc\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" readonly=\"true\" class=\"vgs-loading-placeholder\">\n        <div id=\""
    + escapeExpression(((helper = (helper = helpers.vgsContainerId || (depth0 != null ? depth0.vgsContainerId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"vgsContainerId","hash":{},"data":data}) : helper)))
    + "\" class=\"loading\"></div>\n    </div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.vgsLoaded : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});

templates["datalist"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<datalist id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</datalist>";
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<option value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "</option>";
},"5":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\"></option>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});

templates["date"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<input type=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"10\" size=\"10\" class=\"";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " autocomplete=\"off\">\n";
},"usePartial":true,"useData":true});

templates["ecards"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"at-ecard\">\n            <div class=\"at-ecard-img\">\n                <img src=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(((helper = (helper = helpers.alt || (depth0 != null ? depth0.alt : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"alt","hash":{},"data":data}) : helper)))
    + "\">\n            </div>\n            <input name=\"ecard\" type=\"radio\" value=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helper = (helper = helpers.checked || (depth0 != null ? depth0.checked : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"checked","hash":{},"data":data}) : helper)))
    + ">\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <button type=\"button\" class=\"at-preview-ecard btn-at btn-at-link\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PreviewEcard : stack1), depth0))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"at-title\">\n    "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\n</div>\n<div class=\"at-ecards clearfix\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ecards : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.allowPreview : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});

templates["errors"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<div class=\"at-alert at-error\" tabindex=\"-1\">"
    + escapeExpression(lambda(depth0, depth0))
    + "<a class=\"at-dismiss\" href=\"#\">&times;</a></div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.messages : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});

templates["fa_confirm_account_modal"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <div>\n              <span class=\"glyphicons glyphicons-user\" aria-label=\"User\"></span>\n              <br>\n              <b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.UserName : stack1), depth0))
    + "</b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.UserLocation : stack1), depth0))
    + "\n            </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <div>\n              <span class=\"glyphicons glyphicons-credit-card\" aria-label=\"Credit Card\"></span>\n              <br>\n              <b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.UserCardType : stack1), depth0))
    + "</b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.UserCardDigits : stack1), depth0))
    + "\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<section id=\"fastaction-confirm-account\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"modal-label\" aria-hidden=\"true\" class=\"fa-semantic-content fastaction-modal at\">\n  <div id=\"fastaction-confirm-account-inner\" class=\"fa-modal-inner\">\n    <div class=\"fa-modal-wrapper center\">\n      <div class=\"fa-modal-content center\">\n        <h1>\n          "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ConfirmContactInformation : stack1), depth0))
    + "\n        </h1>\n        <div class=\"fastaction-user-details-container\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowContactDetails : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ShowCardDetails : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n      </div>\n      <div class=\"fa-modal-content center\">\n        <div class=\"panel-buttons center\">\n          <a href=\"#!\" class=\"fastaction-confirm-account-button btn-at btn-at-primary\" data-close=\"Close\" data-dismiss=\"modal\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.YesCompleteDonation : stack1), depth0))
    + "\n          </a>\n        </div>\n        <p class=\"not-me-container\">\n          "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.NoNotMe : stack1), depth0))
    + "\n          <br>\n          <a href=\"#!\" class=\"fastaction-account-not-me-button\" data-close=\"Close\" data-dismiss=\"modal\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ContinueToForm : stack1), depth0))
    + "</a>\n        </p>\n        <div class=\"fastaction-images-container\">\n          <span class=\"fastaction-image\" aria-label=\"FastAction image\"></span>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"fa-modal-overlay\"></div>\n</section>\n";
},"useData":true});

templates["fa_main"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"fastAction clearfix\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.id : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "  <p style=\"line-height: 30px\">\n    <span class=\"fa-cta\">\n      <a href=\"#\" class=\"profile-link toggle-menu\" aria-label=\"FastAction\">\n        <img class=\"profile-link-fa-image\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/fast-action.svg\">\n        <img class=\"toggle-menu-triangle\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/triangle.png\"\n          srcset=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/triangle.svg 2x\">\n      </a>\n      <span>\n        <a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "##whats-this\" class=\"circle\" id=\"fastaction-whatsthis\" data-popup=\"true\"\n          data-popup-width=\"450\" data-popup-height=\"850\" target=\"_blank\">?</a>\n      </span>\n    </span>\n    <span class=\"fa-lead\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.authed : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n  </p>\n  <ul id=\"profile-menu\">\n    <li><a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "/profile\" data-popup=\"true\" target=\"_blank\"\n        id=\"fastaction-profile\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.EditMyBlankProfile : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</a></li>\n    <li><a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "/logout\" data-popup=\"true\" data-popup-width=\"1\" data-popup-height=\"1\"\n        data-popup-top=\"0\" data-popup-left=\"0\" class=\"fastaction-logout\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.LogOut : stack1), depth0))
    + "</a></li>\n  </ul>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Not : stack1), depth0))
    + "&nbsp;<b>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</b>? <a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "/logout\" data-popup=\"true\"\n        data-popup-width=\"1\" data-popup-height=\"1\" data-popup-top=\"0\" data-popup-left=\"0\"\n        class=\"fastaction-logout\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ClickHereLogout : stack1), depth0))
    + "</a>.";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "/logout\" data-popup=\"true\" data-popup-width=\"1\" data-popup-height=\"1\"\n        class=\"fastaction-logout\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ForgetThisDevice : stack1), depth0))
    + "</a>.";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "  <p>\n    <span class=\"fa-cta\">\n      <a href=\"#fastaction-login\" class=\"profile-link\" aria-label=\"FastAction\">\n        <img class=\"profile-link-fa-image\" src=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.ASSET_BASE_URL : stack1), depth0))
    + "/images/fast-action.svg\">\n      </a>\n      <span><a href=\""
    + escapeExpression(((helper = (helper = helpers.fastaction_url || (depth0 != null ? depth0.fastaction_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fastaction_url","hash":{},"data":data}) : helper)))
    + "##whats-this\" class=\"circle\" id=\"fastaction-whatsthis\" data-popup=\"true\"\n          data-popup-width=\"515\" data-popup-height=\"540\" target=\"_blank\">?</a></span>\n    </span>\n    <span class=\"fa-lead\">\n      "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.TakeFutureAction : stack1), depth0))
    + "<br>\n      <a href=\"#fastaction-login\" class=\"call-modal\"\n        id=\"fastaction-widget-login\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.LogIn : stack1), depth0))
    + "</a>&nbsp;"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Or : stack1), depth0))
    + "&nbsp;\n      <a href=\"#fastaction-signup\" class=\"call-modal\"\n        id=\"fastaction-widget-signup\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.SignUp : stack1), depth0))
    + "</a>&nbsp;";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.ForBlank : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </span>\n  </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.cookieCompliance : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});

templates["fa_modal"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<h1>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ThanksForYourSupport : stack1), depth0))
    + "</h1>\n          <p>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.StoredOnThisDevice : stack1), depth0))
    + "</p>\n          <p>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.SignUpForAnAccount : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n          <div class=\"fastaction-images-container\">\n            <span class=\"fastaction-image\" aria-label=\"FastAction image\"></span>\n          </div>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<h1>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.SingleClick : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</h1>\n          <div>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.AutofillQuickly : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.LogIn : stack1), depth0));
  },"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.SignUp : stack1), depth0));
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<section id=\"fastaction-"
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"modal-label\" aria-hidden=\"true\" class=\"fa-semantic-content fastaction-modal at\">\n  <div id=\"fastaction-"
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-inner\" class=\"fa-modal-inner\">\n    <div class=\"fa-modal-wrapper center\">\n      <header>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.nologin : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</header>\n      <div class=\"fa-modal-content center\">\n        <div class=\"panel-buttons center\">\n            <a id=\""
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-fastaction-actionid\" href=\""
    + escapeExpression(((helper = (helper = helpers.faBaseUrl || (depth0 != null ? depth0.faBaseUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"faBaseUrl","hash":{},"data":data}) : helper)))
    + "/"
    + escapeExpression(((helper = (helper = helpers.authRoute || (depth0 != null ? depth0.authRoute : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"authRoute","hash":{},"data":data}) : helper)))
    + "\" class=\"btn-at btn-at-primary\" data-popup=\"true\" data-width=\"500\" data-height=\"535\" target=\"_self\">\n                <span class=\"glyphicons glyphicons-envelope\"></span>\n                "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.ActionText : stack1), depth0))
    + "\n            </a>\n        </div>\n        <p class=\"fa-modal-notusing-wrapper\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.AlreadyHaveFastAction : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.OtherOptionText : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          <a href=\"#fastaction-"
    + escapeExpression(((helper = (helper = helpers.other || (depth0 != null ? depth0.other : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"other","hash":{},"data":data}) : helper)))
    + "\">\n            ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isSignup : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n          </a>\n        </p>\n        <p class=\"fa-modal-disclaimer-wrapper\" style=\"display: none\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.ByClickingAgree : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " <a href=\""
    + escapeExpression(((helper = (helper = helpers.faBaseUrl || (depth0 != null ? depth0.faBaseUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"faBaseUrl","hash":{},"data":data}) : helper)))
    + "/terms\" target=\"_blank\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.TermsOfService : stack1), depth0))
    + "</a> "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.And : stack1), depth0))
    + " <a href=\""
    + escapeExpression(((helper = (helper = helpers.faBaseUrl || (depth0 != null ? depth0.faBaseUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"faBaseUrl","hash":{},"data":data}) : helper)))
    + "/privacy\" target=\"_blank\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PrivacyPolicy : stack1), depth0))
    + "</a></p>\n      </div>\n    </div>\n  </div>\n  <a href=\"#!\" title=\"Close this modal\" data-close=\"Close\" data-dismiss=\"modal\" class=\"fa-modal-close\">&times;</a>\n</section>\n";
},"useData":true});

templates["fieldset"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"at-subtitle\">"
    + escapeExpression(((helper = (helper = helpers.subTitle || (depth0 != null ? depth0.subTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"subTitle","hash":{},"data":data}) : helper)))
    + "</div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.subTitle : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-fields\">\n";
  stack1 = this.invokePartial(partials._rows, '  ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});

templates["form"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.bannerImagePath : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<figure class=\"at-banner\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.returnToWebsiteUrl : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<img src=\""
    + escapeExpression(((helper = (helper = helpers.bannerImagePath || (depth0 != null ? depth0.bannerImagePath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bannerImagePath","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(((helper = (helper = helpers.returnToWebsiteName || (depth0 != null ? depth0.returnToWebsiteName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"returnToWebsiteName","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.returnToWebsiteName || (depth0 != null ? depth0.returnToWebsiteName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"returnToWebsiteName","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.returnToWebsiteUrl : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</figure>";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<a href=\""
    + escapeExpression(((helper = (helper = helpers.returnToWebsiteUrl || (depth0 != null ? depth0.returnToWebsiteUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"returnToWebsiteUrl","hash":{},"data":data}) : helper)))
    + "\">";
},"5":function(depth0,helpers,partials,data) {
  return "</a>";
  },"7":function(depth0,helpers,partials,data) {
  return "at-sr-only";
  },"9":function(depth0,helpers,partials,data) {
  return "at-title";
  },"11":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<header class=\"at-markup MeterHtml\">\n        ";
  stack1 = ((helper = (helper = helpers.meter_html || (depth0 != null ? depth0.meter_html : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meter_html","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </header>";
},"13":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<header class=\"at-markup HeaderHtml\">\n        ";
  stack1 = ((helper = (helper = helpers.header_html || (depth0 != null ? depth0.header_html : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"header_html","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </header>";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"regulations-terms-container\">\n            <p>\n                <a href=\""
    + escapeExpression(((helper = (helper = helpers.regulationsGovUrl || (depth0 != null ? depth0.regulationsGovUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"regulationsGovUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"regulations-doc-link\">\n                    ";
  stack1 = ((helper = (helper = helpers.regulationsGovUrlLabel || (depth0 != null ? depth0.regulationsGovUrlLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"regulationsGovUrlLabel","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                </a>\n            </p>\n            <p class=\"regulations-terms\">By submitting a comment on ";
  stack1 = ((helper = (helper = helpers.regulationsGovDocId || (depth0 != null ? depth0.regulationsGovDocId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"regulationsGovDocId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ", you agree to the <a href=\"https://www.regulations.gov/user-notice\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"regulations-terms-link\">terms of participation</a> and <a href=\"https://www.regulations.gov/privacy-notice\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"regulations-privacy-link\">privacy notice</a> of Regulations.gov.</p>\n        </div>";
},"19":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<ol class=\"at-steps clearfix\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.steps : depth0), {"name":"each","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </ol>\n        <style>\n      .at .at-steps li {";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.staticSteps : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "width: "
    + escapeExpression(((helper = (helper = helpers.stepWidth || (depth0 != null ? depth0.stepWidth : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"stepWidth","hash":{},"data":data}) : helper)))
    + " }\n        </style>";
},"20":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "<li data-step=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" class=\"at-step "
    + escapeExpression(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"classes","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0['static'] : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.program(23, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </li>";
},"21":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<b>\n                    <span class=\"step-title\">"
    + escapeExpression(lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</span>\n                </b>";
},"23":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<a title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" href=\"#"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0['0'] : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n                    <span class=\"step-title\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0['0'] : depth0)) != null ? stack1.title : stack1), depth0))
    + "</span>\n                </a>";
},"25":function(depth0,helpers,partials,data) {
  return "cursor: default;";
  },"27":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.staticSteps : depth0), {"name":"unless","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"step-prevNext clearfix\">\n                <div class=\"prevNext next\">\n                    <button class=\"submitStep btn-at btn-at-primary\">"
    + escapeExpression(((helper = (helper = helpers.submit_label || (depth0 != null ? depth0.submit_label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"submit_label","hash":{},"data":data}) : helper)))
    + "</button>\n                    <a tabindex=\"0\" role=\"button\" class=\"nextStep btn-at btn-at-primary\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Next : stack1), depth0))
    + "</a>\n                </div>\n                <div class=\"prevNext prev\">\n                    <a tabindex=\"0\" role=\"button\" class=\"prevStep btn-at btn-at-link\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Back : stack1), depth0))
    + "</a>\n                </div>\n                <div class=\"at-markup secure-processing-div\">\n";
  stack1 = this.invokePartial(partials['_secure-processing'], '                    ', '_secure-processing', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>";
},"30":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<footer class=\"multistep-footer clearfix\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.returnToWebsiteUrl : depth0), {"name":"if","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paidForByDisclaimerText : depth0), {"name":"if","hash":{},"fn":this.program(33, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <div class=\"poweredby\">";
  stack1 = ((helper = (helper = helpers.poweredby || (depth0 != null ? depth0.poweredby : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"poweredby","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n</footer>";
},"31":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<p class=\"returnToWebsite ngp-returnToWebSite\">\n    <a href=\""
    + escapeExpression(((helper = (helper = helpers.returnToWebsiteUrl || (depth0 != null ? depth0.returnToWebsiteUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"returnToWebsiteUrl","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.returnToWebsiteName || (depth0 != null ? depth0.returnToWebsiteName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"returnToWebsiteName","hash":{},"data":data}) : helper)))
    + "</a>\n  </p>";
},"33":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<p><span class=\"at-paid-for-by\">"
    + escapeExpression(((helper = (helper = helpers.paidForByDisclaimerText || (depth0 != null ? depth0.paidForByDisclaimerText : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"paidForByDisclaimerText","hash":{},"data":data}) : helper)))
    + "</span></p>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isOberonAndKeepHeaderFooter : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<section class=\"at-inner\">\n    <header class=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hideTitle : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</header>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meter_html : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.header_html : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div data-subview=\"error_console\"></div>\n\n    <form class=\""
    + escapeExpression(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"classes","hash":{},"data":data}) : helper)))
    + "\" method=\"post\" novalidate action=\""
    + escapeExpression(((helper = (helper = helpers.form_id || (depth0 != null ? depth0.form_id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"form_id","hash":{},"data":data}) : helper)))
    + "\" accept-charset=\"utf-8\" autocomplete=\"on\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.form_elements : depth0), {"name":"each","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.regulationsGovUrl : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.multistep : depth0), {"name":"if","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-form-submit clearfix\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.multistep : depth0), {"name":"if","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <input type=\"submit\" value=\""
    + escapeExpression(((helper = (helper = helpers.submit_label || (depth0 != null ? depth0.submit_label : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"submit_label","hash":{},"data":data}) : helper)))
    + "\" class=\"at-submit btn-at btn-at-primary\">\n            <div class=\"at-markup secure-processing-single-step-div\">\n";
  stack1 = this.invokePartial(partials['_secure-processing'], '                ', '_secure-processing', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n        </div>\n    </form>\n    <footer class=\"at-markup FooterHtml clearfix\">\n        ";
  stack1 = ((helper = (helper = helpers.footer_html || (depth0 != null ? depth0.footer_html : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"footer_html","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </footer>\n</section>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isOberonAndKeepHeaderFooter : depth0), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div data-subview=\"page_alert\"></div>\n<div class=\"recaptcha-container\"></div>\n";
},"usePartial":true,"useData":true});

templates["generic_modal"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<section id=\""
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"modal-label\" aria-hidden=\"true\" class=\"at-modal "
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-modal\">\n    <div class=\"at-modal-inner\">\n        <header>\n            <div>\n                "
    + escapeExpression(((helper = (helper = helpers.headerText || (depth0 != null ? depth0.headerText : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerText","hash":{},"data":data}) : helper)))
    + "\n            </div>\n        </header>\n        <div class=\"at-modal-content\" id=\""
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-modal-content\"></div>\n        <footer>\n            <div class=\"at-footer-content\" id=\""
    + escapeExpression(((helper = (helper = helpers.prefix || (depth0 != null ? depth0.prefix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prefix","hash":{},"data":data}) : helper)))
    + "-footer-content\"></div>\n        </footer>\n    </div>\n    <a href=\"#!\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Close : stack1), depth0))
    + "\" data-close=\"Close\" data-dismiss=\"modal\" class=\"at-modal-close\">&times;</a>\n</section>\n\n\n";
},"useData":true});

templates["guest_collection"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"ticket-holders\" id=\"ticket-holders-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <ul class=\"ticket-holders-list\">\n  </ul>\n</div>\n";
},"useData":true});

templates["inline_error"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<small class=\""
    + escapeExpression(((helper = (helper = helpers.behavior_classes || (depth0 != null ? depth0.behavior_classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"behavior_classes","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.error_text || (depth0 != null ? depth0.error_text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error_text","hash":{},"data":data}) : helper)))
    + "</small>\n";
},"useData":true});

templates["international_phone"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " (read only)";
  },"3":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " ("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Required : stack1), depth0))
    + ")";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<input type=\"tel\" class=\"intl-phone-"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" ";
  stack1 = ((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n";
},"usePartial":true,"useData":true});

templates["match_pro_input"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  <div id=\"dd-company-name-input\"> </div>\n";
},"usePartial":true,"useData":true});

templates["page_alert"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<div class=\"at-page-alert-wrapper\">\n            <div class=\"at-alert alert\">\n                <span class=\"alert-content\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</span>\n                <a href=\"javascript:void(0);\" class=\"glyphicons glyphicons-remove at-dismiss\"></a>\n            </div>\n        </div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-page-alert\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.alerts : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});

templates["payment_information"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "  <div class=\"at-row\">\n";
  stack1 = this.invokePartial(partials._payment_method_subview_div, '    ', '_payment_method_subview_div', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._cc_readonly, '    ', '_cc_readonly', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ccEnabled : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials._rows, '      ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-row\">\n      <div class=\"at-paypal-accepted-here\" style=\"display: none;\">\n";
  stack1 = this.invokePartial(partials._paypal, '        ', '_paypal', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>";
},"12":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <div class=\"at-row\">\n      <div class=\"at-eft-accepted-here\" style=\"display: none;\">\n";
  stack1 = this.invokePartial(partials._eft, '        ', '_eft', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <div class=\"at-row\">\n      <div class=\"at-apple-pay-accepted-here\" style=\"display: none;\">\n";
  stack1 = this.invokePartial(partials._applePay, '        ', '_applePay', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <div class=\"at-row\">\n      <div class=\"at-google-pay-accepted-here\" style=\"display: none;\">\n";
  stack1 = this.invokePartial(partials._googlePay, '        ', '_googlePay', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </div>\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showPaymentMethods : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-fields\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isReadonly : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isReadonly : depth0), {"name":"unless","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paypalEnabled : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.eftEnabled : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paragonApplePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stripeApplePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.googlePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true});

templates["payment_method"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "at-align-flex-right";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<div class=\"at-btn-radio-wrapper at-credit-card-button-wrapper\">\n      <input id=\"creditcard_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"creditcard\" />\n      <label for=\"creditcard_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" class=\"at-btn-radio large\" tabindex=\"0\">\n        "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithCard : stack1), depth0))
    + "\n      </label>\n    </div>";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "    <div class=\"at-btn-radio-wrapper at-eft-button-wrapper\">\n      <input id=\"eft_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"eft\"/>\n      <label for=\"eft_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" class=\"at-btn-radio large\" tabindex=\"0\">\n        "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithBankAccount : stack1), depth0))
    + "\n      </label>\n    </div>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isBraintreeIntegration : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.program(10, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<div class=\"at-btn-radio-wrapper at-paypal-button-wrapper\">\n        <input id=\"paypal_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"paypal\" />\n        <label for=\"paypal_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\"  class=\"at-btn-radio small at-paypal-button\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithPayPal : stack1), depth0))
    + "\" aria-label=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithPayPal : stack1), depth0))
    + "\" tabindex=\"0\">\n          <span aria-hidden=\"true\">&nbsp;</span> \n        </label>\n      </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "      <div class=\"at-btn-radio-wrapper at-paypal-button-wrapper\">\n        <input id=\"paypal_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"paypal\" />\n        <label for=\"paypal_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\"  class=\"at-btn-radio small at-btn-paypal-commerce-platform\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithPayPal : stack1), depth0))
    + "\" aria-label=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.PayWithPayPal : stack1), depth0))
    + "\" tabindex=\"0\">\n          <div id=\"paypal-button-container\"></div>\n        </label>\n      </div>";
},"12":function(depth0,helpers,partials,data) {
  return "<div class=\"at-btn-radio-wrapper at-btn-radio at-express-checkout-element-wrapper\" style=\"display: none;\"></div>";
  },"14":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"at-btn-radio-wrapper at-apple-pay-button-wrapper\">\n      <input id=\"applepay_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"applepay\"/>\n      <label for=\"applepay_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" class=\"at-btn-radio small\" tabindex=\"0\">\n        <div class=\"at-apple-pay-button at-apple-pay-button-base\">&nbsp;</div>\n      </label>\n    </div>";
},"16":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"at-btn-radio-wrapper at-apple-pay-button-wrapper at-stripe-apple-pay-button-container\" style=\"";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stripeJsEnabled : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n      <input id=\"applepay_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" name=\"PaymentMethod\" value=\"applepay\"/>\n      <label for=\"applepay_radio_"
    + escapeExpression(((helper = (helper = helpers.idSuffix || (depth0 != null ? depth0.idSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idSuffix","hash":{},"data":data}) : helper)))
    + "\" class=\"at-btn-radio small\" tabindex=\"0\">\n        <div class=\"at-apple-pay-button at-apple-pay-button-base\">&nbsp;</div>\n      </label>\n    </div>";
},"17":function(depth0,helpers,partials,data) {
  return " display: none;";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-radio-set at-radio-set-buttons ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.alignRight : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ccEnabled : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.eftEnabled : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paypalEnabled : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.googlePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.paragonApplePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stripeApplePayEnabled : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});

templates["premium_gifts"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <div class=\"at-gift\">\n            <input name=\"gift\" id=\"gift-"
    + escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"val","hash":{},"data":data}) : helper)))
    + "\" type=\"radio\" value=\""
    + escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"val","hash":{},"data":data}) : helper)))
    + "\" data-threshold=\""
    + escapeExpression(((helper = (helper = helpers.threshold || (depth0 != null ? depth0.threshold : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"threshold","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helper = (helper = helpers.checked || (depth0 != null ? depth0.checked : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"checked","hash":{},"data":data}) : helper)))
    + ">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.img_url : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <div class=\"description\">\n                <label class=\"gift-title\" for=\"gift-"
    + escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"val","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</label>\n                <label class=\"gift-description\" for=\"gift-"
    + escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"val","hash":{},"data":data}) : helper)))
    + "\">\n                    ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.threshold : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<span class=\"gift-description-text\">"
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "</span>\n                </label>\n            </div>\n        </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <div class=\"gift-img-container\" aria-hidden=\"true\">\n                    <div class=\"gift-img\">\n                        <img src=\""
    + escapeExpression(((helper = (helper = helpers.img_url || (depth0 != null ? depth0.img_url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img_url","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" onerror=\"this.style.display='none'\">\n                    </div>\n                </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<span class=\"gift-threshold\">"
    + escapeExpression(((helper = (helper = helpers.threshold || (depth0 != null ? depth0.threshold : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"threshold","hash":{},"data":data}) : helper)))
    + "</span>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.willIncrease : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                    ";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                            <span class=\"gift-threshold-warning\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.resources)) && stack1.PrimaryResources)) && stack1.WillIncreaseDonation), depth0))
    + "</span>\n";
},"7":function(depth0,helpers,partials,data) {
  return " - ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-gifts clearfix\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.gifts : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});

templates["question"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<label style=\"padding:0px 0px 10px 10px\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"question-label\">\n    "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "&nbsp;";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<br />\n    <small class=\"question-required-text\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "-small\" style=\"display: none\"><b>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.text : depth0)) != null ? stack1.TitleIsRequired : stack1), depth0))
    + "</b></small>\n</label>\n\n";
},"useData":true});

templates["radios"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<label id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " <small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"4":function(depth0,helpers,partials,data,depths) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<label title=\""
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + "\" class=\"at-radio-label-"
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" role=\"radio\">\n      <input type=\"radio\" name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\" "
    + escapeExpression(((helper = (helper = helpers.checked || (depth0 != null ? depth0.checked : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"checked","hash":{},"data":data}) : helper)))
    + " value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">\n      "
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + "\n    </label>";
},"6":function(depth0,helpers,partials,data) {
  return "<div class=\"radio-description\"></div>";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"radios\" role=\"radiogroup\" aria-labelledby=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.includeDescriptionContainer : depth0), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"useData":true,"useDepths":true});

templates["recaptcha_grid"] = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "    <div class=\"at-recaptcha-wrapper\">\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"2":function(depth0,helpers,partials,data,depths) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "            <div class=\"at-recaptcha clearfix\">\n                <div data-choice=\""
    + escapeExpression(((helper = (helper = helpers.idx || (depth0 != null ? depth0.idx : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idx","hash":{},"data":data}) : helper)))
    + "\"\n                     style=\"width: "
    + escapeExpression(lambda((depths[2] != null ? depths[2].cellWidth : depths[2]), depth0))
    + "; height: "
    + escapeExpression(lambda((depths[2] != null ? depths[2].cellHeight : depths[2]), depth0))
    + ";\n                             background-image:url('"
    + escapeExpression(lambda((depths[2] != null ? depths[2].img : depths[2]), depth0))
    + "');\n                             background-size:"
    + escapeExpression(((helper = (helper = helpers.bkgSize || (depth0 != null ? depth0.bkgSize : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bkgSize","hash":{},"data":data}) : helper)))
    + ";\n                             background-position:"
    + escapeExpression(((helper = (helper = helpers.bkgPos || (depth0 != null ? depth0.bkgPos : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bkgPos","hash":{},"data":data}) : helper)))
    + ";\">\n                </div>\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "<div class=\"at-recaptcha-table\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.gridRow : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true,"useDepths":true});

templates["recipient_information"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row\">\n        ";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, (depth0 != null ? depth0.includeRecipient : depth0), {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"at-fields\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.includeRecipient : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"at-recipient-info\">\n        <div class=\"at-title\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n";
  stack1 = this.invokePartial(partials._rows, '        ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n    <div class=\"at-row\">\n        <div class=\"at-recipient-msg\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.notificationFields : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});

templates["select"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<option value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"disabled","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.selected || (depth0 != null ? depth0.selected : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"selected","hash":{},"data":data}) : helper)))
    + ">"
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + "</option>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<select "
    + escapeExpression(((helper = (helper = helpers.multiple || (depth0 != null ? depth0.multiple : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"multiple","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = helpers.autocomplete || (depth0 != null ? depth0.autocomplete : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autocomplete","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\""
    + escapeExpression(((helper = (helper = helpers.select_classes || (depth0 != null ? depth0.select_classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"select_classes","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(((helper = (helper = helpers.selectId || (depth0 != null ? depth0.selectId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"selectId","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</select>\n";
},"usePartial":true,"useData":true});

templates["shipping_information"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <div class=\"at-row\">\n            <div class=\"at-shipping-cta\">\n                <label class=\"cta-text\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AddressAutoFillPrompt : stack1), depth0))
    + "</label>\n                <a href=\"javascript:void(0);\" class=\"auto-fill\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.AutoFillAddress : stack1), depth0))
    + "</a>\n                <a href=\"javascript:void(0);\" class=\"at-dismiss\">&times;</a>\n            </div>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-fields\">\n    <div class=\"at-row\">\n        <label class=\"at-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.ShipTo : stack1), depth0))
    + "</label>\n    </div>\n    <div class=\"at-row\">\n        <div class=\"at-shipping-destinations\">\n            <label>\n                <input name=\"destinations\" id=\"shipping-dest-yourinfo\" type=\"radio\" value=\"yourinfo\" checked=\"true\">\n                "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.YourInformation : stack1), depth0))
    + "\n            </label>\n            <label>\n                <input name=\"destinations\" id=\"shipping-dest-other\" type=\"radio\" value=\"other\">\n                "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.DifferentAddress : stack1), depth0))
    + "\n            </label>\n        </div>\n    </div>\n    <div class=\"at-shipping-info\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showCta : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials._rows, '        ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});

templates["signature_pad"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " <small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<label>\n  "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>\n<canvas></canvas>\n<div>\n    <button type=\"button\" name=\"clear\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Clear : stack1), depth0))
    + "</button>\n    <button type=\"button\" name=\"undo\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Undo : stack1), depth0))
    + "</button>\n</div>\n";
},"useData":true});

templates["span"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " <small>("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Optional : stack1), depth0))
    + ")</small>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<label for=\"edit-"
    + escapeExpression(((helper = (helper = helpers.name_lower || (depth0 != null ? depth0.name_lower : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name_lower","hash":{},"data":data}) : helper)))
    + "\">\n  "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>\n<span class=\""
    + escapeExpression(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"classes","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"> "
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + " </span>\n";
},"useData":true});

templates["target_select"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<option value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + escapeExpression(((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"disabled","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.selected || (depth0 != null ? depth0.selected : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"selected","hash":{},"data":data}) : helper)))
    + ">"
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + "</option>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"at-select-target\">\n  <b>"
    + escapeExpression(((helper = (helper = helpers.target || (depth0 != null ? depth0.target : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"target","hash":{},"data":data}) : helper)))
    + "</b>\n  <span>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n</div>\n<select required title=\""
    + escapeExpression(((helper = (helper = helpers.target || (depth0 != null ? depth0.target : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"target","hash":{},"data":data}) : helper)))
    + " - "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</select>\n";
},"useData":true});

templates["targets"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "	<div class=\"at-targets-head at-targets-have-images\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.head : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			<figure>\n				<div";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n					<div class=\"at-target-fallback\">\n						<div class=\"at-target-image\" style=\"background-image: url('"
    + escapeExpression(((helper = (helper = helpers.img || (depth0 != null ? depth0.img : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img","hash":{},"data":data}) : helper)))
    + "')\">\n							<img src=\""
    + escapeExpression(((helper = (helper = helpers.img || (depth0 != null ? depth0.img : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"img","hash":{},"data":data}) : helper)))
    + "\" data-idx=\""
    + escapeExpression(((helper = (helper = helpers.idx || (depth0 != null ? depth0.idx : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idx","hash":{},"data":data}) : helper)))
    + "\">\n						</div>\n					</div>\n				</div>\n				<figcaption>\n                    <b>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</b>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta2 : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                </figcaption>\n			</figure>\n";
},"3":function(depth0,helpers,partials,data) {
  return " class=\"at-target-sent\"";
  },"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<br/><span class=\"at-target-meta at-target-meta-1\">"
    + escapeExpression(((helper = (helper = helpers.meta || (depth0 != null ? depth0.meta : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meta","hash":{},"data":data}) : helper)))
    + "</span>";
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<br/><span class=\"at-target-meta at-target-meta-2\">"
    + escapeExpression(((helper = (helper = helpers.meta2 || (depth0 != null ? depth0.meta2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meta2","hash":{},"data":data}) : helper)))
    + "</span>";
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = "	<div class=\"at-targets-head at-targets-no-images\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.head : depth0), {"name":"each","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			<div";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.sent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n                <b>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</b>&nbsp;";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta2 : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            </div>";
  stack1 = helpers.unless.call(depth0, (data && data.last), {"name":"unless","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<span class=\"at-target-meta at-target-meta-1\">"
    + escapeExpression(((helper = (helper = helpers.meta || (depth0 != null ? depth0.meta : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meta","hash":{},"data":data}) : helper)))
    + "</span>";
},"13":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.meta2 : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"14":function(depth0,helpers,partials,data) {
  return "<span class=\"at-target-meta-comma\">, </span>";
  },"16":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<span class=\"at-target-meta at-target-meta-2\">"
    + escapeExpression(((helper = (helper = helpers.meta2 || (depth0 != null ? depth0.meta2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"meta2","hash":{},"data":data}) : helper)))
    + "</span>";
},"18":function(depth0,helpers,partials,data) {
  return "<br>";
  },"20":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "	<hr>\n	<div class=\"at-targets-rest\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.rest : depth0), {"name":"each","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		<br>\n		<a class=\"at-hide-targets\" href=\"#showLess\">Hide recipients</a>\n	</div>\n	<a class=\"at-show-targets\" href=\"#showMore\">+ "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.rest : depth0)) != null ? stack1.length : stack1), depth0))
    + " more recipients</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasImages : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(9, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.rest : depth0)) != null ? stack1.length : stack1), {"name":"if","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});

templates["textarea"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " (read only)";
  },"3":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " ("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Required : stack1), depth0))
    + ")";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<textarea "
    + escapeExpression(((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.readonly || (depth0 != null ? depth0.readonly : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"readonly","hash":{},"data":data}) : helper)))
    + " title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.maxlength || (depth0 != null ? depth0.maxlength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"maxlength","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">"
    + escapeExpression(((helper = (helper = helpers.default_value || (depth0 != null ? depth0.default_value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"default_value","hash":{},"data":data}) : helper)))
    + "</textarea>\n";
},"usePartial":true,"useData":true});

templates["textfield"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " (read only)";
  },"3":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " ("
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.resources : depth0)) != null ? stack1.PrimaryResources : stack1)) != null ? stack1.Required : stack1), depth0))
    + ")";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<input type=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.autocomplete || (depth0 != null ? depth0.autocomplete : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autocomplete","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = helpers.pattern || (depth0 != null ? depth0.pattern : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"pattern","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = helpers.readonly || (depth0 != null ? depth0.readonly : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"readonly","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += " title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" maxlength=\""
    + escapeExpression(((helper = (helper = helpers.maxlength || (depth0 != null ? depth0.maxlength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"maxlength","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n";
},"usePartial":true,"useData":true});

templates["ticket_holder"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return " (Required)";
  },"3":function(depth0,helpers,partials,data) {
  return " required";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<td>\n  <label class=\"ticket-guest-first-label\">\n    <kbd>First Name</kbd>\n    <input type=\"text\" maxlength=\"20\" placeholder=\"First Name\" title=\"Guest First Name";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" class=\"ticket-guest-first\" id=\"ticket-guest-first-"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" name=\"ticket-guest-first-"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.first || (depth0 != null ? depth0.first : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"first","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " />\n  </label>\n</td>\n<td>\n  <label class=\"ticket-guest-last-label\">\n    <kbd>Last Name</kbd>\n    <input type=\"text\" maxlength=\"25\" placeholder=\"Last Name\" title=\"Guest Last Name";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" class=\"ticket-guest-last\" id=\"ticket-guest-last-"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" name=\"ticket-guest-last-"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.last || (depth0 != null ? depth0.last : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"last","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.required : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " />\n  </label>\n<td>\n  <label for=\"ticket-guest-first-"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.level : depth0)) != null ? stack1.title : stack1), depth0))
    + "</label>\n</td>\n";
},"useData":true});

templates["ticketing"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<legend class=\"at-legend\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</legend>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row at-row-solo at-row-full TicketHeaderHtml\">\n    <div class=\"at-markup TicketHeaderHtml\">";
  stack1 = ((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"header","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n  </div>";
},"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<tr data-subview='ticketing_level_view' data-subview-id='"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "' data-subview-index='"
    + escapeExpression(lambda((data && data.index), depth0))
    + "'></tr>";
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<tr>\n        <td colspan=\"3\">\n          <div data-subview='textfield_view' data-subview-index='"
    + escapeExpression(((helper = (helper = helpers.additional || (depth0 != null ? depth0.additional : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"additional","hash":{},"data":data}) : helper)))
    + "'></div>\n        </td>\n      </tr>";
},"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<table class=\"at-table TicketHolders\">\n    <thead>\n      <tr>\n        <th colspan=\"2\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.guest_names : depth0)) != null ? stack1.label : stack1), depth0));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.optionalTicketHolders : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " </th>\n        <th>Ticket Level</th>\n      </tr>\n    </thead>\n    <tbody>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ticket_holders : depth0), {"name":"each","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </tbody>\n  </table>";
},"10":function(depth0,helpers,partials,data) {
  return "<small class=\"optional\">&nbsp;(Optional)</small>";
  },"12":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<tr data-subview='ticket_holder_view' data-subview-id='"
    + escapeExpression(((helper = (helper = helpers.cid || (depth0 != null ? depth0.cid : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"cid","hash":{},"data":data}) : helper)))
    + "' data-subview-index='"
    + escapeExpression(lambda((data && data.index), depth0))
    + "'></tr>";
},"14":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"at-row\">\n    <div data-subview='select_view' data-subview-index='"
    + escapeExpression(((helper = (helper = helpers.host || (depth0 != null ? depth0.host : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"host","hash":{},"data":data}) : helper)))
    + "'></div>\n  </div>";
},"16":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"at-row\">\n        <div data-subview='cover_costs_checkbox_view' data-subview-index='"
    + escapeExpression(((helper = (helper = helpers.coverCosts || (depth0 != null ? depth0.coverCosts : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"coverCosts","hash":{},"data":data}) : helper)))
    + "'></div>\n    </div>";
},"18":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"at-row at-row-solo at-row-full TicketFooterHtml\">\n    <div class=\"at-markup TicketFooterHtml\">";
  stack1 = ((helper = (helper = helpers.footer || (depth0 != null ? depth0.footer : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"footer","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n  </div>";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div class=\"at-fields\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.header : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<table class=\"at-table TicketLevels\">\n  <thead>\n    <th colspan=\"2\">Ticket Level</th>\n    <th>Quantity</th>\n  </thead>\n  <tbody>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.ticket_levels : depth0), {"name":"each","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.additional : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <tr class=\"ticketTotals\">\n      <td class=\"pricingTotal\" colspan=\"2\">\n        <span class=\"totalAmount\"><b class=\"label\">TOTAL:</b>&nbsp;<b data-total=\"price\">"
    + escapeExpression(((helpers.formatMoney || (depth0 && depth0.formatMoney) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.total : stack1), {"name":"formatMoney","hash":{},"data":data})))
    + "</b></span>\n      </td>\n      <td class=\"ticketTotal\">\n        <b class=\"label\">QTY:</b>&nbsp;<b data-total=\"count\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.stats : depth0)) != null ? stack1.count : stack1), depth0))
    + "</b>\n      </td>\n    </tr>\n  </tbody>\n</table>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showTicketHolders : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.host : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.coverCosts : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.footer : depth0), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});

templates["ticketing_level"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<br /><small class=\"ticket-description\">"
    + escapeExpression(((helper = (helper = helpers.publicDescription || (depth0 != null ? depth0.publicDescription : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"publicDescription","hash":{},"data":data}) : helper)))
    + "</small>";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <select id=\"at-ticket-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"ticket-quantity-select\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.levels : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</select>\n";
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<option value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.display || (depth0 != null ? depth0.display : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"display","hash":{},"data":data}) : helper)))
    + "</option>";
},"6":function(depth0,helpers,partials,data) {
  return "      <span class=\"ticket-sold-out\">Sold Out</span>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<td colspan=\"2\">\n  <label for=\"at-ticket-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <span class=\"ticket-name\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + " ("
    + escapeExpression(((helpers.formatMoney || (depth0 && depth0.formatMoney) || helperMissing).call(depth0, (depth0 != null ? depth0.price : depth0), {"name":"formatMoney","hash":{},"data":data})))
    + ")</span>";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.publicDescription : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n</td>\n<td>\n  <label>\n    <kbd>Number of "
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + " tickets ("
    + escapeExpression(((helpers.formatMoney || (depth0 && depth0.formatMoney) || helperMissing).call(depth0, (depth0 != null ? depth0.price : depth0), {"name":"formatMoney","hash":{},"data":data})))
    + " each)</kbd>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.levels : depth0)) != null ? stack1.length : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(6, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </label>\n</td>\n";
},"useData":true});

templates["time"] = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials._label, '', '_label', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<input type=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\" class=\"timepicker ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" placeholder=\""
    + escapeExpression(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = ((helper = (helper = helpers.required || (depth0 != null ? depth0.required : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"required","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " autocomplete=\"off\">\n";
},"usePartial":true,"useData":true});

templates["tribute_gift"] = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.subview || (depth0 && depth0.subview) || helperMissing).call(depth0, depth0, {"name":"subview","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"at-fields\">\n";
  stack1 = this.invokePartial(partials._rows, '    ', '_rows', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <div class=\"at-row\">\n        <div class=\"at-tribute-gift\" style=\"display:none\">";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.giftChildren : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});

export default templates;
/* jshint ignore:end */
