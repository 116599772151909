//This file is generated via grunt build and should not be modified directly
/* jshint ignore:start */
export default {
  "VGS_VAULT_ID": "tntw1pznlam",
  "VGS_JS_URL": "https://js.verygoodvault.com/vgs-collect/2.18.4/vgs-collect.js",
  "ASSET_BASE_URL": "//static.everyaction.com/ea-actiontag/assets",
  "ZIP_LOOKUP_BASE_URL": "//static.everyaction.com/ea-actiontag/assets/zip",
  "VGS_ENVIRONMENT": "live"
};
/* jshint ignore:end */
