import Backbone from 'lib/backbone.subviews/backbone.subviews';
import Spinner from 'lib/spin';
import DoubleBracket from 'app/DoubleBracket';
// ErrorViews was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'app/views/ErrorViews';
import oberon from 'app/oberon';
import trackTransaction from 'app/trackTransaction';
import NVFormView from 'app/views/NVFormView';
import NVForm from 'app/models/NVForm';
import FormDefUtils from 'app/FormDefUtils';
// FormElements was imported when this was an AMD module. Unsure if it's needed for a side effect
import 'app/FormElements';
import invokeCallbacks from 'app/NVTagCallbacks';
import parseUrl from 'lib/parseUrl';
import qsParams from 'data/qs.params';
import socialSharePopup from 'app/socialSharePopup';
import socialMediaLogger from 'app/socialMediaLogger';
import Map from 'app/Map';
import 'app/SignaturePad';
import vgs from 'app/vgs';
import html2canvasWrapper from 'app/html2canvasWrapper';
import deviceInfo from 'app/deviceInfo';
import databag_config from 'app/databag_config';
import lightboxHelper from 'app/views/helpers/lightboxHelper';
import checkAds from 'app/checkAds';
import aliasHelper from 'app/aliasHelper';
import uuid from 'lib/uuid/uuid';
import telemetry from 'app/views/helpers/telemetry';
import matchPro from 'app/matchPro';
import FastActionUser from 'app/models/FastActionUser';
import formStateHelper from 'app/formStateHelper';


// NVTagView.js: Main file for the Tag.
//   It is the master controller of most things. Waits for DOM ready given its
//   tight coupling to the DOM for a lot of what it does.

var $ = Backbone.$,
  _ = Backbone._;

// Always initialize VGS in case we need it
vgs.init();

function FormViewArray() {
  this.current = null;
}

FormViewArray.prototype = [];
FormViewArray.prototype.getCurrent = function () {
  return this.current;
};
FormViewArray.prototype.setCurrent = function (view) {
  this.current = view;
  return this;
};

export default Backbone.View.extend({
  __name__: 'NVTagView',
  interstitial: false,
  events: {
    'submit form': 'postBack',
    'click #rta-link': 'closeWindowRedirect'
  },
  /**
   * Redirect the window then close the window
   */
  closeWindowRedirect: function () {
    var qs = this.options.query_string;
    window.location = qs.redirect;
    setTimeout(function () {
      //Allow time for browser to redirect before closing window
      window.close();
    }, 8000);

  },
  currentFormView: function () {
    return this.formviews.getCurrent();
  },
  bringIntoView: function () {
    $('html, body').scrollTop(this.$el.offset().top - 100);
  },
  initialize: function () {
    _.bindAll(this, 'render', 'segue', 'segueUrl', 'postBack', 'startPostBack', 'handleLightboxAcceptance');

    // This should be a collection of forms, prepopulated with the first.
    this.formviews = new FormViewArray();

    // Segue to the initial form id
    this.segue(this.options.initial_form_id);

    // There can be only one.
    this.$el.attr('id', 'NVTag' + (this.options.index + 1)).data('view', this);
  },
  segueUrl: function (url) {
    this.options.formUrl = url;
    var oberonUri = parseUrl(this.options.formUrl),
      oberonUrl = decodeURIComponent(oberonUri.pathname.split('/')[1]),
      oberonSegment = parseUrl(oberonUrl),
      formId = oberonSegment.pathname.split('/')[2];
    return this.segue(formId);
  },
  segue: function (new_id) {
    $('body').removeClass('page-ngp-multistep');
    this.$el.
      removeClass('fastaction-enabled multistep-layout nv-template-oberon nv-template-accelerator split-layout');
    /*jshint -W116 */
    if (this.currentFormView() != null && this.currentFormView().multistep && this.currentFormView().parentOptions) {
      /*jshint -W116 */
      this.options = _.extend(this.options, this.currentFormView().parentOptions);
    }

    // TODO: Stop setting irrelevant crap on the NVForm model!!!!
    var newForm = new NVForm({
      id: new_id,
      options: this.options
    });

    // Fetch the next form
    // Future performance enhancement -- we should not wait until segue to request
    // the next form, we can/should request it prior to segue and have it sitting, waiting
    // to significantly speed this process along.
    var newFormView = this.newNVFormView(newForm);

    // If this form was segued into from a previous form. Then previous form values
    // need to populate this form.
    /*jshint -W116 */
    if (this.currentFormView() != null) {
      /*jshint +W116 */
      newFormView.options.last_form_state =
        formStateHelper.transformFormStateForSegue(this.currentFormView().options.last_form_state);
      this.currentFormView().remove();
    }


    this.formviews.push(newFormView);
    this.formviews.setCurrent(newFormView);

    var qs = this.options.query_string;
    if (qs.redirect) {

      var leftPointingTriangle = '&#x25c0;';
      var isIOS = navigator.userAgent.match(/(iPhone|iPod|iPad)/);

      if (isIOS) {
        //prevent IOS from displaying unicode as emoji
        var noEmojiIOS = '&#xFE0E;';
        leftPointingTriangle += noEmojiIOS;
      }

      // Add link back to MiniVAN application
      this.$el.append('<a id="rta-link" class="btn-at btn-at-primary">' + leftPointingTriangle + ' Back to MiniVAN</a>');
    }
    this.$el.append(newFormView.$el);

    return this.render();
  },
  newNVFormView: function (form) {
    var formview = new NVFormView({
      model: form,
      parent: this,
      parent_tag: this,
      labels: this.options.labels,
      query_string: this.options.query_string,
      post_response: this.options.post_response || null,
      previous_payment: this.options.previous_payment || null,
      inline_error_display: this.options.inline_error_display,
      mobileAutofocus: this.options.mobileAutofocus
    });
    formview.parent = this;
    return formview;
  },
  spin: function ($target) {
    if (Spinner) {
      $target.spin();
    }
    return this;
  },
  setupShareUrls: function () {

    var qs_field_dict = this.qs_field_dict;
    // The markup for the facebook and twitter buttons comes with the thankyou text.
    // It does require a click handler which we need to define and register here to support embedded forms
    var facebookShareButton = this.$('#fbShareBtn');
    var trackingId = formview.model.get('trackingId');
    // Make sure the share button is on the page
    if (facebookShareButton.length) {
      var fbTrackingUrl = $('#fbShareBtn').attr('data-trackingurl');
      var doNotModifyForEmbeddedFb = typeof $('#fbShareBtn').attr('data-donotmodifyforembedded') !== 'undefined';
      // Register a click handler.
      // Using jquery instead of backbone-foo to register the callback
      facebookShareButton.click(function (event) {
        event.stopPropagation();
        // Disable the button to avoid double clicks/race conditions
        facebookShareButton.prop('disabled', true);
        // Record somewhere that the social media button was clicked
        if (trackingId) {
          socialMediaLogger.recordActivity(formview.form_definition().url.full, trackingId, 'Facebook', function () {
            facebookShareButton.prop('disabled', false);
          });
        }
        socialSharePopup.popupFunction(event, 'https://www.facebook.com/sharer.php?u=', fbTrackingUrl, doNotModifyForEmbeddedFb, 520, 350, qs_field_dict);

      });
    }
    var twitterShareButton = this.$('#twShareBtn');
    // Make sure the share button is on the page
    if (twitterShareButton.length) {
      var twTrackingUrl = $('#twShareBtn').attr('data-trackingurl');
      var tweetText = $('#twShareBtn').attr('data-text');
      var doNotModifyForEmbeddedTw = typeof $('#twShareBtn').attr('data-donotmodifyforembedded') !== 'undefined';
      // Register a click handler.
      // Using jquery instead of backbone-foo to register the callback
      twitterShareButton.click(function (event) {
        event.stopPropagation();
        // Disable the button to avoid double clicks/race conditions
        twitterShareButton.prop('disabled', true);
        // Record somewhere that the social media button was clicked
        if (trackingId) {
          socialMediaLogger.recordActivity(formview.form_definition().url.full, trackingId, 'Twitter', function () {
            twitterShareButton.prop('disabled', false);
          });
        }
        if (tweetText) {
          socialSharePopup.popupFunction(event, 'https://twitter.com/intent/tweet?text=' + tweetText + '&url=', twTrackingUrl, doNotModifyForEmbeddedTw, 550, 420, qs_field_dict);
        } else {
          socialSharePopup.popupFunction(event, 'https://twitter.com/intent/tweet?url=', twTrackingUrl, doNotModifyForEmbeddedTw, 520, 350, qs_field_dict);
        }
      });
    }

  },
  render: function (html) {
    // empty() faster than html(null) see http://vq.io/YGVN01
    // NO LONGER USE empty() as it kills all Event binding!
    // this.$el.empty();

    // check ads
    checkAds();

    if (this.options.offline) {
      var message = '<p>Sorry, our form processing system is down right now. Please try again shortly.</p>';
      if (this.options.offline_message) {
        message = this.options.offline_message;
      }
      this.$el.html(message);
      return this;
    }

    // If specific HTML was passed in, render that. Used primarily for Thank You pages
    if (typeof html !== 'undefined') {
      // This should be redone as a template...
      this.$('.ngp-spinner').remove();
      this.currentFormView().$el
        .html('<div class="content thankYou"><section class="contributions">' + html + '</section></div>').show();

      this.setupShareUrls();
    } else if (!this.currentFormView()) {
      // TODO: un-kludge
      return;
    } else if (this.interstitial) {
      // If interstitial set to true, hide the form, show a spinner
      this.$('.thankYou').remove();
      this.currentFormView().$el.hide();
      var $spinner = $('<div id="progress-' + this.currentFormView().cid +
        '" class="ngp-spinner" style="width: 100%; height: 200px;"></div>');
      this.$el.append($spinner);
      this.spin($spinner);
    } else {
      // Hide the Spinner, show the form
      this.$('.ngp-spinner, .thankYou').remove();
      this.currentFormView().$el.show();
    }
    return this;
    // Render creates the divs to hold forms in the collection. Triggers for form rendering are totally independent.
  },
  postBack: function (e, form_state, skipSteps, submitType) {

    // Prevent hard form submit.
    if (_.isObject(e) && _.isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    // if submit type is missing, check the event type
    submitType = submitType || (e && e.type);

    // disable all auto submitting to ensure we only ever try this once per tag
    this.disableAutoSubmit = true;

    var formview = this.currentFormView();

    if (formview.multistep && formview.getStepIndex() + 1 !== formview.steps.length && !skipSteps) {
      return formview.nextStep();
    }

    // Scroll back up to the top
    this.bringIntoView();

    var errors = formview.errors();
    if (errors.length) {
      invokeCallbacks('formErrors', { errors: errors });
      return formview.renderFeedback();
    }

    // TODO: This is symptomatic of a wider issue with the way we handle async data layers.
    // Should use async library. - Bjorn
    var form_elements = formview.form_definition().form_elements;
    var form_fields = FormDefUtils.fields(form_elements);
    var hidden_fields = _.filter(form_fields, { type: 'hidden' });

    // Since the contact information fieldset gets blown away on advocacy forms
    // Build the query string dictionary here and now, but only once
    if (!this.qs_field_dict) {
      this.qs_field_dict = _.transform(
        // The current formfiew references what was returned in the thank you response
        // we want the query strings from the original form/model
        FormDefUtils.querystring_dict(formview.form_definition().form_elements, 'queryString'),
        // The qs dict keys are all made lowercase so as to ensure case-insensitive behavior.
        // Since we're finding the union of the two key sets, we need to lower-case this as well.
        function (result, value, key) {
          result[key.toLowerCase()] = value;
        }
      );

      //Add smartlink query strings to the dictionary as well
      // We will want to ensure that these are also removed from the shared link
      // https://ngpvan.atlassian.net/browse/DIGITAL-9327
      // https://ngpvan.atlassian.net/browse/VAN-37038 Remove anything that might have been added by tge smartlinks
      _.extend(this.qs_field_dict, {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        emci: '',
        emdi: '',
        fn: '',
        mn: '',
        ln: '',
        em: '',
        add1: '',
        ci: '',
        st: '',
        pc: '',
        hp: '',
        mp: '',
        wp: '',
        ep: '',
        oc: '',
        p: '',
        s: '',
        smartlinkdata: '', //legacy smartlink qs from TGE
        contactdata: '' //encrypted smartlink qs from TGE
      });
    }


    var hidden_form_state = _.reduce(hidden_fields, function (memo, field) {
      // Exclude hidden values with an empty string value
      if (field.value !== '') {
        memo[field.name] = field.value;
      }
      return memo;
    }, {});

    // Add MarketSource and MailingId, if relevant.
    var qs = this.options.query_string;
    _.each(qsParams, function (val, key) {
      if (qs[key]) {
        hidden_form_state[val] = qs[key];
      }
    });

    if (hidden_form_state.MarketSource) {
      hidden_form_state.MarketSource = hidden_form_state.MarketSource.substring(0, 149);
    }

    if (formview.form_definition().designation) {
      hidden_form_state.designationName = formview.form_definition().designation.name;
    }

    // Get the current state of the form, store it on this form so we
    // can use it later to re-fill the form if it comes back with errors
    // from the POST.
    form_state = formview.options.last_form_state = form_state ?
      _.merge(formview.val(), form_state) :
      formview.val();

    // Special handling for setting employer to MatchPro value if its otherwise missing
    if (form_state.MatchProCompanyName && form_state.MatchProIsFound && !form_state.Employer) {
      var employerComponent = _.filter(form_fields, { name: 'Employer' });
      if (employerComponent) {
        form_state.Employer = form_state.MatchProCompanyName;
      }
    }

    var submissionCorrelationProperties = {
      ClientSubmissionId: uuid.v4(),
      FormSessionId: formview.formSessionId
    };

    // Validations pass, so we will start the process of submitting the form
    telemetry.trackEvent({
      name: 'ActionTag Submit Start',
      properties: _.defaults({
        FormId: formview.form_definition().formId,
        FormShortCode: formview.form_definition().shortCode,
        FormViewIndex: this.formviews.indexOf(formview),
        SubmissionType: submitType,
        FormType: formview.form_definition().type,
        PaymentMethod: form_state.PaymentMethod,
      }, submissionCorrelationProperties)
    });

    var self = this;

    function handleSubmissionFailure(failureReason) {
      telemetry.trackEvent({
        name: 'ActionTag Submit Resolution',
        properties: _.defaults({
          Status: 'Failed',
          FailureReason: failureReason,
        }, submissionCorrelationProperties)
      });

      self.interstitial = false;
      self.render();
    }

    function getVgsValuesAndPostback() {
      // show spinner
      self.interstitial = true;
      self.render();

      return formview.getVgsValues(submissionCorrelationProperties).then(function (vgsValues) {
        vgsValues = vgsValues || {};
        var vgsKeys = Object.keys(vgsValues);
        _.forEach(vgsKeys, function (key) {
          // add copy of each with Redacted prefix
          vgsValues['Redacted' + key] = vgsValues[key];
        });

        form_state = _.merge(form_state, vgsValues);
        try {
          return self.startPostBack(e, formview, form_state, hidden_form_state, qs, submissionCorrelationProperties);
        } catch (postBackError) {
          var error = postBackError || new Error('Start PostBack Error');
          telemetry.trackException({
            exception: error,
            properties: _.defaults({
              ErrorType: 'Start PostBack Exception'
            }, submissionCorrelationProperties)
          });
          handleSubmissionFailure('Start PostBack Exception');
        }
      }, function (vgsValuesErr) {
        try {
          var error = vgsValuesErr || new Error('VGS Values Error');
          var metaData = {
            ErrorType: 'VGS Exception',
            FormSessionId: formview.formSessionId,
            ClientSubmissionId: submissionCorrelationProperties.ClientSubmissionId
          };
          telemetry.trackException({ exception: error, properties: metaData });
        } catch (logerr) {
          // swallow
        }
        console.error(vgsValuesErr);
        handleSubmissionFailure('VGS Exception');
      });
    }

    function getRecaptchaValueAndPostback(next) {
      return formview.getRecaptchaValues(submissionCorrelationProperties).then(function (recaptchaValues) {
        form_state = _.merge(form_state, recaptchaValues);
        return next();
      }, function (err) {
        console.error(err);
        try {
          var error = err || new Error('Recaptcha Execution Error');
          var metaData = {
            ErrorType: 'Recaptcha Exception',
            FormSessionId: formview.formSessionId,
            ClientSubmissionId: submissionCorrelationProperties.ClientSubmissionId,
            InternalAttempts: formview.captcha.internalAttempts
          };
          telemetry.trackException({ exception: error, properties: metaData });
        } catch (logerr) {
          // swallow
        }

        handleSubmissionFailure('Recaptcha Exception');
      });
    }

    function captureFormImage() {
      // Do NOT put spinners before this call otherwise it will
      // break w/ an empty canvas being rendered
      return html2canvasWrapper.htmlToCanvas($(formview.$el)[0])
        .then(function (canvas) {
          var dataURI = canvas.toDataURL('image/png');
          form_state.formCaptureImage = dataURI.replace('data:image/png;base64,', '');
        })
        .then(getVgsValuesAndPostback);
    }

    try {
      // trigger captcha first to ensure it happens before displaying a spinner
      // then if the form has "captureFormImage" enabled, do the form capture
      // then get VGS fields
      // then initiate the postback
      var afterCaptcha = formview.form_definition().metadata.captureFormImage
        ? captureFormImage
        : getVgsValuesAndPostback;
      return getRecaptchaValueAndPostback(afterCaptcha);

    } catch (err) {
      telemetry.trackException({
        exception: err,
        properties: _.defaults({ ErrorType: 'Unhandled Submission Error' }, submissionCorrelationProperties)
      });
      console.error(err);

      // hide spinner
      handleSubmissionFailure('Unhandled Exception');
    }
  },
  handleLightboxAcceptance: function (originalResponse, formview) {
    // For now, assume all lightbox acceptance is an upsell, and all upsells have payment method views
    // Directly locate the payment method view, since we'll need to await the action it takes, making events not suitable

    // Update the contribution model to be aware of the proposed upsell state.  Necessary for handling validation.
    formview.contributionAmountModel.setProposedUpsellState(originalResponse.response.proposedUpsellRecurringAmount,
      originalResponse.response.proposedUpsellOneTimeAmount);

    var paymentMethodView = formview.subviewsWithName('PaymentMethod');

    // If we're missing the payment view, hope the form config knows what its doing and let it continue
    // to process the upsell
    if (!paymentMethodView) {
      return $.Deferred().resolve();
    }

    paymentMethodView = paymentMethodView[0];
    return paymentMethodView.onUpsellAccepted();
  },
  startPostBack: function (e, formview, form_state, hidden_form_state, qs, submissionCorrelationProperties) {
    // Explicitly remove Signature Pad values everywhere because they are huge.
    // We have to keep formCaptureImage because it's needed to
    // generate PDFs (VAN) server side
    // Use explicit search to support IE
    form_state = _.omit(form_state, function (value, key) {
      return key.indexOf('SignaturePad_') === 0;
    });

    // form optimization experiments: stuff query params into the post here
    if (!!formview.options.form_definition.experiment && !!formview.options.form_definition.experiment.chosenVariantId) {
      form_state.ExperimentVariantId = formview.options.form_definition.experiment.chosenVariantId;
    }
    // form version gets put into every request (if it exists)
    if (!!formview.options.form_definition.formVersion) {
      form_state.FormVersion = formview.options.form_definition.formVersion;
    }

    // fix any field values that should be aliased to something else
    aliasHelper.fixAliasSubmissionValues(formview.aliases, form_state);

    form_state = _.extend(hidden_form_state, form_state);

    // Tell Oberon which type of form we're posting
    form_state.type = formview.form_definition().type;

    delete form_state.formSessionId;
    delete form_state.FormSessionId;
    form_state.FormSessionId = formview.formSessionId;
    form_state.ClientSubmissionId = submissionCorrelationProperties.ClientSubmissionId;

    delete form_state.BrowserName;
    form_state.BrowserName = deviceInfo.browser;

    delete form_state.DeviceType;
    form_state.DeviceType = deviceInfo.deviceType;

    // 4 is the "This Date" option; if it's not the selected option, then don't send EndDate.
    if (form_state.EndDate === null || parseInt(form_state.SelectedDuration, 10) !== 4) {
      delete form_state.EndDate;
    }

    // Delete the "HostCommittee" key if it is null.
    if (form_state.HostCommittee === null || form_state.HostCommittee === 'null') {
      delete form_state.HostCommittee;
    }

    // remove unecessary payment method information from form post
    if (form_state.PaymentMethod !== 'paypal') {
      delete form_state.PayPalNonce;
    }

    if (form_state.PaymentMethod !== 'eft') {
      delete form_state.BankAccountNumber;
      delete form_state.BankAccountType;
      delete form_state.RoutingNumber;
    }

    if (form_state.PaymentMethod !== 'applepay') {
      delete form_state.PaymentResponseDetails;
    }

    if (form_state.PaymentMethod !== 'googlepay') {
      delete form_state.ConfirmationTokenId;
    }

    if (form_state.PaymentMethod !== 'creditcard') {
      delete form_state.Account;
      delete form_state.ExpirationMonth;
      delete form_state.ExpirationYear;
      delete form_state.SecurityCode;
      delete form_state.CreditCardLastFour;
      delete form_state.CreditCardLastFourType;
      delete form_state.FastActionTokenPool;
    }

    if (e) {
      e.data = form_state;
      e.formview = formview;
      invokeCallbacks('onSubmit', e);
    } else {
      invokeCallbacks('onSubmit', {
        data: form_state,
        formview: formview
      });
    }

    var self = this;
    function handleErrorResponse(errors, serverResponse) {
      self.interstitial = false;
      delete self.options.query_string.tknfa;

      // Remove prior response to lightbox - we want to present to the user again after they correct errors
      delete form_state.LightboxResponse;

      self.render().$('[name="ConfirmationID"]').remove();
      formview.subviews.error_console.setServerErrors(errors).renderFeedback();

      // in case this form has recaptcha, we need to invalidate the token
      if (!serverResponse || serverResponse.captchaReset) {
        formview.resetRecaptcha();
      }

      _.forEach(errors, function (error) {
        telemetry.trackTrace({
          message: 'ActionTag handleErrorResponse',
          properties: {
            Details: error.text,
            Severity: error.severity,
            FormSessionId: formview.formSessionId,
            ClientSubmissionId: submissionCorrelationProperties.ClientSubmissionId
          }
        });
      });

      telemetry.trackEvent({
        name: 'ActionTag Submit Resolution',
        properties: _.defaults({
          Status: 'Failed',
          FailureReason: serverResponse ? 'Form POST Server Error' : 'Form POST Failure',
        }, submissionCorrelationProperties)
      });
    }

    // Flatten object by forcing serialization of values.
    // Needed because oberon expects standard post string like this (e.g.):
    // FirstName=Bjorn&TicketHolders={'FirstName':'Joe','LastName':'Hill'}
    function serialize(state) {
      return _.reduce(state, function (memo, value, key) {
        if (_.isNull(value)) {
          return memo;
        }
        memo[key] = _.isString(value) ? value : JSON.stringify(value);
        return memo;
      }, {});
    }

    form_state = serialize(form_state);

    var takeConfirmationAction = function (response, employerMatchingResponse) {
      var trueResponse = 'response' in response ? response.response : response;
      var confirmationParams = 'page' in response ? response.page : {};

      invokeCallbacks('preSegue', {
        formdef: formview.model.toJSON(),
        postVals: formview.val(),
        response: trueResponse,
        confirmation: confirmationParams
      });

      var duration = ~~((_.now() - formview.startTime) / 1000);
      window.onbeforeunload = null;

      switch (response.page.confirmationActionType) {
        // Secondary ask form
        case 1:
        case 'InternalPage':
          if (form_state.autoCreateAccount || form_state.updateProfile) {
            // The new form is filled using the existing FastActionUser model. We don't want to fill
            // the segued form with the old FastAction values if we're updating the profile. While we refetch the
            // FastActionUser, there is the theoretical potential for a race condition where the form renders and fills the old values,
            // briefly flashing them before the fetch call completes (which will also trigger a fill).
            // Set the FastActionUser model to the new values to prevent this.
            FastActionUser.setProfileDataWithFormState(form_state);
            FastActionUser.setSavedCardWithPostResponse(response);
            // if a new FA user was created during the submission or the profile was updated, fetch its info using the now-persisted
            // FastActionSessionId from localStorage. We need the user info to fill the new form
            FastActionUser.fetch();
          }
          nvtag.track(form_state.type, 'Form Redirect', 'Secondary Ask', duration, formview);
          this.options.previous_payment = {};
          _.each(['Account', 'ExpirationMonth', 'ExpirationYear'], function (key) {
            if (form_state[key]) {
              this.options.previous_payment[key] = form_state[key];
            }
          }, this);
          if (response.page.confirmationRedirectFormUrl) {
            this.segueUrl(response.page.confirmationRedirectFormUrl);
          } else {
            if (this.options.formUrl) {
              this.options.formUrl = null;
            }
            this.segue(response.page.confirmationRedirectFormId);
          }
          break;
        // External URL
        case 2:
        case 'ExternalPage':
          nvtag.track(form_state.type, 'Form Redirect', 'External URL', duration, formview);

          // DO NOT USE this.options.query_string, since nvtag.js (where is created)
          // forces all qs keys to lowercase
          // if the redirect url includes query strings just append these query strings to the end
          //remove first character, which will always be '?'
          window.location = response.page.confirmationRedirectUrl +
            (window.location.search ?
              (response.page.confirmationRedirectUrl.indexOf('?') >= 0 ? '&' : '?') +
              window.location.search.substring(1)
              : '');

          break;
        // Thank you message
        case 3:
        case 'DisplayContent':
          nvtag.track(form_state.type, 'Form Redirect', 'Thank You', duration, formview);
          var html;

          // Advocacy forms do not have the thank you page content in the formdef
          // so first check the page response
          if (response && response.page && response.page.confirmationPageContent) {
            html = response.page.confirmationPageContent;
          } else {
            html = '';
          }

          // If we have an employer matching result, modify the returned content to include it
          if (employerMatchingResponse && employerMatchingResponse.responseHtmlFragment) {
            // OA inserts a special placeholder if the merge field is used
            var employerMatchingResultPlaceholder = employerMatchingResponse.responsePlaceholder;
            var employerMatchingResultHtml = employerMatchingResponse.responseHtmlFragment;
            if (employerMatchingResultPlaceholder && html.includes(employerMatchingResultPlaceholder)) {
              html = html.replace(employerMatchingResultPlaceholder, employerMatchingResultHtml);
            } else {
              html += employerMatchingResultHtml;
            }
          }

          if (qs.redirect) {
            var cbData = _.reduce(qsParams.minivan, function (memo, key) {
              if (form_state[key]) {
                memo[key] = form_state[key];
              }
              return memo;
            }, {
              ConfirmationID: response.response.confirmationID,
              TrackingID: response.response.trackingId
            });
            try {
              cbData.SelectedDuration = formview.getField('SelectedDuration').def.options[cbData.SelectedDuration].display;
              cbData.SelectedFrequency = formview.getField('SelectedFrequency').def.options[cbData.SelectedFrequency].display;
            } catch (e) { }
            var cb = qs.redirect + '?' + $.param(cbData);
            this.$('#rta-link').attr('href', cb);
            var redirectAfter = parseInt(qs.redirectafter, 10);
            if (redirectAfter) {
              setTimeout(function () {
                window.location = cb;
                window.close();
              }, redirectAfter * 1000);
              html += '<br>Click the link at the top if you\'re not returned to MiniVAN in ' + redirectAfter + ' seconds.';
            }
          }
          // Store off the tracking id.  We're going to need it when we post recuiter tracking clicks
          formview.model.set({ 'trackingId': response.response.trackingId });

          this.render(html);

          // As long as we didn't register with CyberGrants, setup the MatchPro widget
          if (employerMatchingResponse && employerMatchingResponse.status !== 'RegisteredWithCyberGrants' && matchPro.isConfigured()) {
            matchPro.setupConfirmationPageWidget(employerMatchingResponse.matchProDonationIdentifier, employerMatchingResponse.matchProCompanyId);
          }

          // load maps if there any
          Map.loadMap('at-event-map-container');

          if (window.FB && window.FB.XFBML) {
            window.FB.XFBML.parse(this.el);
          }

          if (window.twttr && window.twttr.widgets) {
            var loc = window.location;
            var shareUrl = loc.protocol + '//' + loc.hostname + loc.pathname;
            this.$('a[href="https://twitter.com/share"]').attr('data-url', shareUrl);
            window.twttr.widgets.load(this.el);
          }

          invokeCallbacks('postRender', {
            form_definition: null,
            options: this.options,
            thank: true
          });

          var fill_dict = _.extend({}, response.response, form_state);
          var fullName = '';
          if (fill_dict.FirstName) {
            fullName += fill_dict.FirstName;
          }
          if (fill_dict.MiddleName) {
            if (fullName) {
              fullName += ' ';
            }
            fullName += fill_dict.MiddleName;
          }
          if (fill_dict.LastName) {
            if (fullName) {
              fullName += ' ';
            }
            fullName += fill_dict.LastName;
          }
          fill_dict.FullName = fullName;

          DoubleBracket.fill(this, fill_dict);
          invokeCallbacks('segue', {
            formviews: this.formviews,
            thank: true,
            calling_tag: this
          });
          break;
        case 4:
        case 'Lightbox':
          var self = this;
          // If we successfully upsell the donor, in some cases we'll need to have them reauthorize their payment
          // This preAccept method returns a promise indicating whether they needed to authorize but did not.
          var preAcceptCallback = function () {
            return self.handleLightboxAcceptance(response, formview);
          };
          var postAcceptCallback = function () {
            // Once the supporter accepts and any necessary work has been done,
            // submit the form as accepted
            return self.postBack(null, { LightboxResponse: 'accept' }, true, 'upsellAccept');
          };
          var declineCallback = function () {
            // Unset any proposed upsell state after a decline
            formview.contributionAmountModel.setProposedUpsellState(0, 0);

            // If the supporter declines, submit the form with that value
            return self.postBack(null, { LightboxResponse: 'decline' }, true, 'upsellDecline');
          };

          var lightbox = lightboxHelper(response.page.lightboxData, declineCallback, preAcceptCallback, postAcceptCallback);
          this.render();
          lightbox.show();
          break;
      }
    };
    takeConfirmationAction = _.bind(takeConfirmationAction, this);

    var processSubmissionResponse = function (response) {
      var trueResponse = 'response' in response ? response.response : response;

      if (trueResponse.customerKey && trueResponse.ccInfoKey) {
        this.options.customerKey = trueResponse.customerKey;
        this.options.ccInfoKey = trueResponse.ccInfoKey;
      }
      else if (trueResponse.confirmationID) {
        this.options.confirmationID = trueResponse.confirmationID;
      }

      telemetry.trackEvent({
        name: 'ActionTag Submit Resolution',
        properties: _.defaults({
          Status: 'Success'
        }, submissionCorrelationProperties)
      });

      var submitText = formview.$('input[type="submit"]').text() || 'Contribute';
      var amount = 0;
      if (form_state.Amount) {
        amount = Math.round(form_state.Amount);
        amount = _.isNaN(amount) ? 0 : amount;
      }

      nvtag.track(form_state.type, 'Form Submit', submitText, amount, formview);
      trackTransaction(formview, trueResponse);
    };

    processSubmissionResponse = _.bind(processSubmissionResponse, this);

    var registerEmployerMatch = function (employerMatchInfo) {
      var endpoint = employerMatchInfo.endpoint;
      var payload = {
        workEmail: employerMatchInfo.email,
        matchProCompanyId: employerMatchInfo.matchProCompanyId,
        submissionTrackingId: employerMatchInfo.submissionTrackingId,
        campaign: employerMatchInfo.campaign
      };

      var ajaxOptions = {
        type: 'POST',
        url: endpoint,
        data: payload,
        //headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        crossDomain: true,
        xhrFields: { withCredentials: true }
      };

      telemetry.trackEvent({ name: 'ActionTag EmployerMatching Request', properties: submissionCorrelationProperties });

      // In a future ticket we'll do something with a successful result.
      return $.ajax(ajaxOptions).fail(function (e) {
        console.error('Error during employer matching call', e);
      });
    };

    registerEmployerMatch = _.bind(registerEmployerMatch, this);

    var body = {
      data: form_state,
      url: this.options.endpoint,
      form_id: formview.model.id,
      form_definition: formview.form_definition()
    };

    if (formview.form_definition().submissionUrl) {
      body.url = formview.form_definition().submissionUrl;
      body.form_id = '';
    }

    body.endpoint = body.url;

    var post = invokeCallbacks('alterPost', body);

    if (!post.data.Account) {
      if (this.options.customerKey && this.options.ccInfoKey) {
        post.data.CustomerKey = this.options.customerKey;
        post.data.CcInfoKey = this.options.ccInfoKey;
      } else if (this.options.confirmationID) {
        post.data.ConfirmationID = this.options.confirmationID;
      }
    }

    if (telemetry.isEnabled()) {
      // check if any of the inputs in the form's DOM have the autofill pseudo-class
      // so we can determine if the values was sourced by autofill prior to submit
      try {
        var results = _.map(formview.$el.find(':-webkit-autofill'), function (el) {
          var data = _.pick(el, 'name', 'tagName', 'type', 'value');
          if (!_.contains(databag_config.allowlist, data.name)) {
            // redact if it isnt in our allowlist
            data.value = (data.value || '').toString().replace(/./g, '*');
            data.redacted = true;
          }
          return data;
        });
        if (results && results.length) {
          telemetry.trackEvent({
            name: 'ActionTag Autofill', properties: {
              Names: _.map(results, 'name').join(','),
              Details: JSON.stringify(results),
              FormSessionId: formview.formSessionId
            }
          });
        }
      } catch (err) {
        // womp
      }
    }

    var success_callback = _.bind(function (response) {
      telemetry.trackEvent({ name: 'ActionTag Form Post Response', properties: submissionCorrelationProperties });

      // Necessary because Oberon returns a true JSON object, Drupal returns a string
      // that must be parsed as JSON. In the future, we can remove this, but it needs
      // to stay here as long as Drupal passthrough used for anything.
      if (!_.isObject(response)) {
        response = JSON.parse(response);
      }

      // Store this PostBack data for use in later filling
      this.options.post_response = response.response;

      // If any errors, don't want to segue to next form
      if (response && response.errors && response.errors.length > 0) {
        handleErrorResponse(response.errors, response);
      } else {

        this.interstitial = false;

        processSubmissionResponse(response);

        // if the submission was successful and resulted in the creation of a new FastAction account,
        // save the FastActionSessionId of the new account in localStorage to keep the supporter logged in
        if (post.data.autoCreateAccount === 'true' && post.data.fastActionSessionId) {
          FastActionUser.persistSessionId(post.data.fastActionSessionId);
        }

        // If we need to register an employer match, do so now
        // Then continue to the confirmation action dictated by the response
        var employerMatchInfo = response.employerMatchingInfo;
        if (employerMatchInfo) {
          registerEmployerMatch(employerMatchInfo).then(function (employerMatchingResults) {
            telemetry.trackEvent({ name: 'ActionTag EmployerMatching Response', properties: submissionCorrelationProperties });

            takeConfirmationAction(response, employerMatchingResults);
          }, function (error) {
            // Log the error
            error = error || new Error('ActionTag EmployerMatching Error');
            telemetry.trackException({
              exception: error,
              properties: _.defaults({
                ErrorType: 'EmployerMatching Exception'
              }, submissionCorrelationProperties)
            });

            // Continue with the main submission flow
            takeConfirmationAction(response);
          });
        } else {
          takeConfirmationAction(response);
        }
      }
    }, this);

    var error_callback = _.bind(function (xhr, textStatus, errorThrown) {
      var response = {};
      this.interstitial = false;

      if (xhr.responseText) {
        response = JSON.parse(xhr.responseText);
      }

      if (textStatus === 'timeout') {
        console.warn('Unfortunately, a timeout occurred.');
      } else {
        console.warn(response.message || 'Unspecified Error');
      }

      telemetry.trackTrace({
        message: 'ActionTag Form POST error',
        properties: {
          Details: response.message || 'Unspecified Error',
          Severity: 'Critical',
          TextStatus: textStatus,
          ErrorThrown: errorThrown,
          FormSessionId: formview.formSessionId
        }
      });


      var messageText = formview.options.form_definition.resources.PrimaryResources.ProblemProcessingSubmission;
      var error = [{ properties: ['Unknown'], severity: 'Critical', text: messageText }];
      handleErrorResponse(error);

    }, this);

    function postForm() {
      telemetry.trackEvent({
        name: 'ActionTag Form Post',
        properties: submissionCorrelationProperties
      });
      return oberon.post_form(post.url, post.form_id, post.data, success_callback, error_callback);
    }

    return postForm();
  }
});
