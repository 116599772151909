import Braintree from 'lib/braintree';
import PayPal from 'lib/paypal';
import { loadScript as payPalCommercePlatformLoadScript } from 'lib/PayPalCommercePlatform';
import $ from 'lib/jquery.ui';
import telemetry from 'app/views/helpers/telemetry';

function initPayPalBraintreeInstance(formUrl) {
  return new window.Promise(function (resolve, reject) {

    $.ajax({
      url: formUrl,
      xhrFields: { withCredentials: true },
      type: 'GET'
    }).done(function (token) {
      // Create a Braintree Client instance.
      Braintree.create({
        authorization: token
      }).then(function (clientInstance) {
        telemetry.trackEvent({
          name: 'PayPal Braintree: created Braintree client instance',
          properties: {}
        });

        // Create a PayPal Checkout instance.
        return PayPal.create({
          client: clientInstance
        }).then(function (paypalInstance) {
          telemetry.trackEvent({
            name: 'PayPal Braintree: created PayPal client instance',
            properties: {}
          });

          resolve(paypalInstance);
        }).catch(function (e) {
          telemetry.trackException(e, {
            ErrorType: 'PayPal Braintree',
            Message: 'Failed to create PayPal client instance'
          });

          throw e;
        });
      }).catch(function (err) {
        telemetry.trackException(err, {
          ErrorType: 'PayPal Braintree',
          Message: 'Failed to create Braintree client instance'
        });

        var payPalInstanceFailed = true;
        reject(payPalInstanceFailed);
      });
    }).fail(function (xhr, status, err) {
      telemetry.trackException(err, {
        ErrorType: 'PayPal Braintree',
        Message: 'Failed to get token during init',
        HttpStatus: status
      });

      var payPalInstanceFailed = true;
      reject(payPalInstanceFailed);
    });
  });
}

function initPayPalCommercePlatformInstance(paymentMethodView) {
  return new window.Promise(function (resolve, reject) {
    
    payPalCommercePlatformLoadScript({
      'client-id': paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
      'merchant-id': paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId,
      'locale': 'en_US',
      'disable-funding': 'card,paylater,credit,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
      'intent': 'authorize'
    }).then(function (paypal) {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'silver',
          layout: 'vertical',
          label: 'paypal',
          height: 46

        },
        createOrder: function (data, actions) {
          var amount = paymentMethodView.contributionAmountModel.get('totalAmount');

          telemetry.trackEvent({
            name: 'PayPalCommercePlatform: creating order',
            properties: {
              'ClientId': paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
              'MerchantId': paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId,
              'Amount': amount,
              'Data': data
            }
          });

          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount
              },
            }],
            intent: 'AUTHORIZE'
          });
        },
        onApprove: function (data, actions) {

          telemetry.trackEvent({
            name: 'PayPalCommercePlatform: approving order',
            properties: {
              'ClientId': paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
              'MerchantId': paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId,
              'Data': data
            }
          });

          actions.order.authorize().then(function (authorization) {
            telemetry.trackEvent({
              name: 'PayPalCommercePlatform: order approved',
              properties: {
                'ClientId': paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
                'MerchantId': paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId,
                'Authorization': authorization
              }
            });

            resolve(authorization);
          });
        },
        onCancel: function (data) {
          telemetry.trackEvent({
            name: 'PayPalCommercePlatform: buyer canceled',
            properties: {
              'ClientId': paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
              'MerchantId': paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId,
              'Data': data
            }
          });
          resolve(false);
        },
        onError: function (err) {
          telemetry.trackException(err, {
            ErrorType: 'PayPalCommercePlatform',
            Message: 'Buttons generated error',
            ClientId: paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
            MerchantId: paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId
          });
          reject();
        }
      }).render('#paypal-button-container');

    })
      .catch(function (err) {
        console.error('failed to load the PayPal JS SDK script', err);
        telemetry.trackException(err, {
          ErrorType: 'PayPalCommercePlatform',
          Message: 'Failed to load SDK',
          ClientId: paymentMethodView.options.formview.options.form_definition.metadata.payPalClientId,
          MerchantId: paymentMethodView.options.formview.options.form_definition.metadata.payPalMerchantId
        });
      });
  });
}

function mapPayPalCommercePlatformResponse(paypalPayer) {
  var address = paypalPayer.address;
  var phone = paypalPayer.phone;
  var dict = {
    FirstName: paypalPayer.given_name,
    LastName: paypalPayer.surname,
    AddressLine1: address && address.address_line_1,
    AddressLine2: address && address.address_line_2,
    City: address && address.admin_area_2,
    StateProvince: address && address.admin_area_1,
    PostalCode: address && address.postal_code,
    Country: address && address.country_code,
    EmailAddress: paypalPayer.email_address,
    HomePhone: phone && phone.national_number,
    MobilePhone: phone && phone.national_number
  };
  return dict;
}

function mapPayPalBraintreeResponse(paypalDetails) {
  // If we get the billing address, use that.  Otherwise, use shipping.
  // (Accounts need a special permission from PayPal to get the billing address)
  // We sometimes only get a country code in billing address, so ensure there's a real address there.
  var address = isValidAddress(paypalDetails.billingAddress) ? paypalDetails.billingAddress : paypalDetails.shippingAddress;
  var dict = {
    FirstName: paypalDetails.firstName,
    LastName: paypalDetails.lastName,
    AddressLine1: address && address.line1,
    AddressLine2: address && address.line2,
    City: address && address.city,
    StateProvince: address && address.state,
    PostalCode: address && address.postalCode,
    Country: address ? address.countryCode : paypalDetails.countryCode,
    EmailAddress: paypalDetails.email,
    HomePhone: paypalDetails.phone,
    MobilePhone: paypalDetails.phone
  };
  return dict;
}

function isValidAddress(address) {
  return address && address.line1 && address.city && address.postalCode;
}

export default {
  initPayPalBraintreeInstance: initPayPalBraintreeInstance,
  initPayPalCommercePlatformInstance: initPayPalCommercePlatformInstance,
  mapPayPalCommercePlatformResponse: mapPayPalCommercePlatformResponse,
  mapPayPalBraintreeResponse: mapPayPalBraintreeResponse
};
